import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin, Table, Button, Popconfirm, Space, Tooltip } from "antd";
import "./styles.css";
import AppBreadCrumb from '../header/breadcrumb';
import { AddCart } from '../../actions/action'
import { connect } from 'react-redux';
import { useHistory } from "react-router";
import { Radio } from 'antd';
import moment from "moment";

const isRegistrationOpen = (date) => {
  if (!date) {
    return true
  } 

  return moment().utc(true).isSameOrAfter(moment(date, 'DD/MM/YYYY').utc(true))
}

const camelCaseToNormal = (camelString) => {
  return camelString.replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, (str) => (str.toUpperCase()))
}

const CaftaCaseChampionship = ({ certificateData, AddCart }) => {
  const { Meta } = Card;
  const [addItem, setAddItem] = useState({});
  const history = useHistory();


  const navTo = (link) => {
    history.push(link);
  }

  useEffect(() => {
    console.log(certificateData);
    const temp = {
      key: certificateData.key,
      id: certificateData.id,
      title: certificateData.title,
      category: certificateData.category,
      price: certificateData.price,
      currency: certificateData.currency,
      img: certificateData.img
    }
    setAddItem(temp);
  }, []);

  const addToCart = (item, teamSize) => {
    console.log(item);
    console.log(teamSize);
    const data = {
      key: item.key,
      id: item.id,
      title: item.title,
      category: item.category,
      price: (teamSize === 1 ? 2000 : 3000),
      currency: 'INR',
      teamSize: teamSize,
      img: ''
    }

    AddCart(data);


  }

  const downloadBrochure = (key) => {
    var a = document.createElement("a"); //Create <a>
    a.href = `/brochure/${key}.pdf`; //Image Base64 Goes here
    a.download = "brochure.pdf"; //File name Here
    a.click();
}

  const [stream, setStream] = useState('underGrad');
  const onChange = (e) => {
    setStream(e.target.value);
  };

  return (
    <Row className="pt-82 bg-grey2">
      <Col className="mb-40" span={24}>
        <AppBreadCrumb></AppBreadCrumb>
      </Col>
      <Col span={22} offset={1}>
        {certificateData && Object.keys(certificateData).length ? (

          <Row gutter={[24, 24]} className="skill-up-challenge-sec mt-40 mb-40">
            {certificateData.description.cards.map(item => (
              <Col md={24} lg={item.col} className="description-sec">
                <Row className="description-sec-wrapper">
                  <Col span={24} className="sec-title mb-20">
                    <h3 >{item.heading}</h3>
                  </Col>
                  <Col span={24} className="about-challenge mt-20">
                    {Object.keys(item).map(obj => {
                      return ((obj !== "addToCart" && obj !== "table" && obj !== "col" && obj !== "heading" && obj !== "forMoreInformationContact" && obj !== "basicRegister" && obj !== "advanceRegister" && obj !== "downloadBrochure") ? (
                        <>
                          <p className="fw-600">{camelCaseToNormal(obj)}</p>
                          <ul>
                            {Array.isArray(item[obj]) ? item[obj].map(list => (
                              <li>{list}</li>
                            )) : (<p>{item[obj]}</p>)}
                          </ul>
                        </>) : obj === "table" ?
                        (<div className="hedge-accounting-table"><Table bordered dataSource={item[obj].dataSource} columns={item[obj].columns} pagination={{ position: ['none', 'none'], pageSize: 11 }}  /></div>)
                        : obj === "addToCart" ? (
                          <div className="">
                            <div className="hedge-accounting-btn text-center mb-10">
                              <Button onClick={() => AddCart(addItem)}>Add To Cart</Button>
                            </div>
                          </div>
                        ) : obj === "forMoreInformationContact" ? (
                          <div>
                            <br></br>
                            <p className="fw-600">{camelCaseToNormal(obj)}</p>
                            <b>{item[obj][0][0]}</b><br></br>
                            <>Email : <a href={`mailTo:cafta@in.ey.com`}>{item[obj][0][1]}</a></><br></br>
                            <br></br>
                            <p>Or write us at <a href="mailTo:cafta@in.ey.com">cafta@in.ey.com</a></p>
                            {/* <>Mobile : <a href="tel:+917303943512">{item[obj][2]}</a></> */}
                          </div>
                        ) : (obj === "advanceRegister" || obj === "basicRegister") ? (
                          <>
                            <Radio.Group value={stream} size="large" onChange={onChange}>
                              <Space direction="horizontal" style={{justifyContent: 'center'}}>
                                <Radio.Button value="underGrad">Under Graduate</Radio.Button>
                                <Radio.Button value="postGrad">Post Graduate</Radio.Button>
                              </Space>
                            </Radio.Group>
                            <Row gutter={[24, 24]} className="live-training-list-sec" style={{ marginTop: 10 }}>
                              {item[obj].filter(item => item.stream === stream).map((item, index) => { 
                                return (
                                  <Col key={`${item.title}-${index}`} m={{ span: 8 }}>
                                    <Card className="stream-card"
                                      style={{ width: 400 }}
                                      actions={[
                                        <Popconfirm
                                          title="Are you a Team of 1 or 2?"
                                          onConfirm={() => addToCart(item, 2)}
                                          onCancel={() => addToCart(item, 1)}
                                          okText={2}
                                          cancelText={1}
                                          disabled={!isRegistrationOpen(item.registrationStartDate)}
                                        >
                                          <Tooltip
                                            title={!isRegistrationOpen(item.registrationStartDate) ? item.disabledRegistrationMessage : undefined}
                                          >
                                            <Button style={{ background: '#2e2e38c7', borderColor: '#2e2e38', color: 'rgb(228 222 222 / 85%)' }} disabled={!isRegistrationOpen(item.registrationStartDate)}>Add to cart</Button>    
                                          </Tooltip>
                                        </Popconfirm>
                                      ]}
                                      title={item.title}
                                    >
                                      <p style={{textAlign: 'justify', height: '225px', whiteSpace: 'pre-wrap'}}>{item.description}</p>
                                    </Card>
                                  </Col>
                                )
                              })}
                            </Row>
                          </>
                        ) : obj === "downloadBrochure" ? (
                          <div >
                            <div className="hedge-accounting-btn text-center mb-10">
                              <Button onClick={() => downloadBrochure(certificateData.key)}>Download Brochure</Button>
                            </div>
                          </div>

                          ) : ""

                      )
                    })}

                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <Col span={24} className="text-center mt-40 mb-40">
              <Spin tip="Loading..."></Spin>
            </Col>
          </Row>

        )}
      </Col>
    </Row>

  );
}
// export default HedgeAccounting;
export default connect(null, { AddCart })(CaftaCaseChampionship)