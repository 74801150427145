import { Row, Col, Form, Input, Button, Popover } from "antd";
import React, { useState } from "react";
import "../sign-in/sign-in.css";
import { Auth } from "aws-amplify";
import { Store } from "react-notifications-component";
import { NavLink, useHistory } from "react-router-dom";
const passwordValidator = require("password-validator");

export default function ForgotPassword() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isConfirmationCodeSent, setIsConfirmationCodeSent] = useState(false);

  const schema = new passwordValidator();
  schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

  const formatPasswordValidateError = (errors) => {
    for (const error of errors) {
      if (error === "min") {
        return "password length should be a at least 8 characters";
      } else if (error === "lowercase") {
        return "password should contain lowercase letters";
      } else if (error === "uppercase") {
        return "password should contain uppercase letters";
      } else if (error === "digits") {
        return "password should contain digits";
      } else if (error === "symbols") {
        return "password should contain symbols";
      }
    }

    /* for (let i = 0; i < errors.length; i++) {
        if (errors[i] === 'min') {
            return 'password length should be a at least 8 characters';
        } else if (errors[i] === 'lowercase') {
            return 'password should contain lowercase letters';
        } else if (errors[i] === 'uppercase') {
            return 'password should contain uppercase letters';
        } else if (errors[i] === 'digits') {
            return 'password should contain digits';
        } else if (errors[i] === 'symbols') {
            return 'password should contain symbols';
        }
    } */
  };

  const validateToNextPassword = (rule, value, callback) => {
    // const form = this.props.form;
    const validationRulesErrors = schema.validate(value, { list: true });

    // if (value && this.state.confirmDirty) {
    //   form.validateFields(['confirm'], { force: true });
    // }
    if (validationRulesErrors.length > 0) {
      callback(formatPasswordValidateError(validationRulesErrors));
    }
    callback();
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    if (!isConfirmationCodeSent) {
      try {
        const response = await Auth.forgotPassword(values.email);
        setIsConfirmationCodeSent(true);
        Store.addNotification({
          title: "Success",
          message: `Verification Code has been sent to ${response.CodeDeliveryDetails.Destination} ${response.CodeDeliveryDetails.DeliveryMedium}`,
          type: "info",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__flipInx"],
          animationOut: ["animate__animated", "animate__flipOutx"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
          },
        });
      } catch (error) {
        console.log(error);
        Store.addNotification({
          title: error.code || "Error",
          message: error.message || "Something went wrong",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__flipInx"],
          animationOut: ["animate__animated", "animate__flipOutx"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
          },
        });
      }
      setLoading(false);
    } else {
      // Collect confirmation code and new password , then
      try {
        setLoading(true);
        await Auth.forgotPasswordSubmit(
          values.email,
          values.confirmationCode,
          values.newPassword
        );
        Store.addNotification({
            title: "Success",
            message: `Password updated. Please login.`,
            type: "info",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__flipInx"],
            animationOut: ["animate__animated", "animate__flipOutx"],
            dismiss: {
              duration: 5000,
              onScreen: true,
              showIcon: true,
            },
          });
        history.replace("/sign-in")
      } catch (error) {
        Store.addNotification({
          title: error.code || "Error",
          message: error.message || "Something went wrong",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__flipInx"],
          animationOut: ["animate__animated", "animate__flipOutx"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
          },
        });
      }
      setLoading(false);
    }
  };

  return (
    <Row className="sign-in-up pt-82 bg-grey2">
      <Col span="18" offset={3} className="mt-40 signin-min-height mb-40">
        <div className="container-sec">
          <div className="rightSide">
            <div className="title">One of us?</div>
            <div className="text">
              If you already have an account you can log in.
            </div>
          </div>
          <div className="bar">
            <div className="mask">
              <div className="toggle">
                <div className="dot">.</div>
                <NavLink to="/sign-in">
                  <div
                    className="signupText"
                    style={{
                      color: "black",
                    }}
                  >
                    Sign&nbsp;In
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="loginBlock">
            <div
              className="loginContent"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3>Forgot Password</h3>
              <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    disabled={isConfirmationCodeSent}
                  />
                </Form.Item>

                {isConfirmationCodeSent && (
                  <>
                    <Form.Item
                      name="confirmationCode"
                      rules={[
                        {
                          required: true,
                          message: "Verification code is required",
                        },
                      ]}
                    >
                      <Input placeholder="Code" />
                    </Form.Item>
                    <Popover
                      placement="right"
                      title={"Password Policy"}
                      content={
                        <React.Fragment>
                          <h4>Your password should contain: </h4>
                          <ul>
                            <li>Minimum length of 8 characters</li>
                            <li>Numerical characters (0-9)</li>
                            <li>Special characters</li>
                            <li>Uppercase letter</li>
                            <li>Lowercase letter</li>
                          </ul>
                        </React.Fragment>
                      }
                      trigger="focus"
                    >
                      <Form.Item
                        name="newPassword"
                        rules={[
                          {
                            validator: validateToNextPassword,
                          },
                        ]}
                      >
                        <Input placeholder="New Password" />
                      </Form.Item>
                    </Popover>
                  </>
                )}

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                  {isConfirmationCodeSent && (
                    <Button
                      type="link"
                      onClick={() => {
                        setIsConfirmationCodeSent(false);
                      }}
                    >
                      Not your email. Try Again?
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
