import React from "react";
import { Row, Col, Card, Empty, Spin } from "antd";
import {
  NavLink
} from "react-router-dom";
import "./executives.css";
import AppBreadCrumb from '../header/breadcrumb';


const Executives = ({ scholarInfo }) => {
  const { Meta } = Card;



  return (
    <Row className="pt-82 bg-grey2">
      <Col className="mb-40" span={24}>
        <AppBreadCrumb></AppBreadCrumb>
      </Col>
      <Col span={22} offset={1}>
        {scholarInfo && Object.keys(scholarInfo).length ? (
          <Row className="scholar-sec mt-40 mb-40">
            <Col span={24} className="scholar-sec-container">
              <Row>
                <Col span={24} className="scholar-title mt-5 pr-15 pl-15 mb-20">
                  <h3 >CAFTA Executives</h3>
                </Col>
                <Col span={24} className="scholar-description pr-15 pl-15 mt-20">
                  <p>{scholarInfo.description.p1}</p>
                  <p>{scholarInfo.description.p2}</p>
                  <p>{scholarInfo.description.p3}</p>
                </Col>
              </Row>
            </Col>

            <Col span={24} className="mt-20 live-training-wrapper">
              <Row>
                <Col span={24} className="live-training-title pr-15 pl-15 mt-5 mb-20">
                  <h3 >E-Trainings</h3>
                </Col>
                <Col span={24} className="mt-20 mb-20">
                  <Row className="live-training-list-sec ">
                    {scholarInfo.liveTrainingList && scholarInfo.liveTrainingList.length ? (
                      scholarInfo.liveTrainingList.map((item, i) => (
                        <Col key={`${item.title}-${i}`} span={4} className="pr-15 pl-15 mb-20 card-container">
                          <Card
                            className="front-card"
                            hoverable
                            cover={<NavLink to={item.url}>
                              <img alt="Course" src={item.img} />
                            </NavLink>
                            }>
                            <Meta title={item.title} description={
                              <Row>
                                <Col span={24} className="lang-info">LANGUAGE: {item.lang}</Col>
                                <Col span={24}>
                                  <h6 className="price">PRICE:  {item.price} {item.currency}</h6>
                                </Col>
                              </Row>
                            } />
                          </Card>
                        </Col>
                      ))

                    ) : (
                      <Col span={24} className="text-center mt-40 mb-40">
                        <Empty />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24} className="text-center mt-40 mb-40">
              <Spin tip="Loading..."></Spin>
            </Col>
          </Row>

        )}
      </Col>
    </Row>

  );
}
export default Executives;