import React from "react";
import VideoTeaser from "../video-teaser/video-teaser";
import YoutubeEmbed from "./YoutubeEmbed";

const ContentBox = ({ story, titleStory, afterwords, embedId }) => {
  return (
    <div >
      {/* <h3 className="testimonials__content-box-title">{titleStory}</h3>
      <p className="testimonials__content-box-story">{story}</p>
      <span className="testimonials__content-box-afterwords">{afterwords}</span> */}
      {/* <iframe width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe> */}
      {/* <VideoTeaser></VideoTeaser> */}
      <YoutubeEmbed embedId={embedId}></YoutubeEmbed>
    </div>
  );
};

export default ContentBox;
