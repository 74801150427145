import { combineReducers } from 'redux';
import {GET_ALL_PRODUCT, GET_NUMBER_CART, ADD_CART, DECREASE_QUANTITY, INCREASE_QUANTITY, DELETE_CART, GET_MODULE_ITEM, CLEAR_CART} from  '../actions/action';
import { Store } from 'react-notifications-component';
import moment from 'moment';

const initProduct = {
    numberCart:0,
    carts:[],
    moduleList:[],
    moduleItem: {}
}

function caftaModules(state = initProduct,action){
    switch(action.type){
        case GET_ALL_PRODUCT: 
            return{
                ...state,
                moduleList:action.payload
            }
        case GET_MODULE_ITEM: 
            return{
                ...state,
                moduleItem:action.payload
            }    
        case GET_NUMBER_CART:
                return{
                    ...state
                }
        case ADD_CART:
            // console.log(action.payload.selectedSession);
            // if (action.payload.selectedSession) {
            //     const selectetdData = (action.payload.selectedSession).split(',')[0]
            //     // var date = moment("02/05/2021", "DD/MM/YYYY");
            //     // console.log(moment(new Date()));

            //     console.log(selectetdData > moment(new Date()).format('DD/MM/YYYY'));
            //     if (selectetdData > moment(new Date()).format('DD/MM/YYYY')) {
            //         return;
            //     }
                
            // }
            console.log(action.payload);
            if(state.numberCart === 0){
                let cart = {
                    key: action.payload.id,
                    id: action.payload.id,
                    quantity: 1,
                    title: action.payload.title,
                    img: action.payload.img,
                    category: action.payload.category,
                    price: action.payload.price,
                    selectedSession: action.payload.selectedSession,
                    currency: action.payload.currency,
                    teamSize: action.payload.teamSize
                } 
                
                state.carts.push(cart); 
                const msg = cart.category === 'module' ? `"${cart.title}" added to the cart successfully. Please add one more module to place order` : `"${cart.title}" added to the cart successfully.`
                showNotification('Added To The Cart!!', msg, 'success');
                return{
                    ...state,
                    numberCart:state.numberCart+1
                }
            } else{
                const filterCategory = state.carts.filter(data => data.category === action.payload.category);
                if (!filterCategory.length) {
                    showNotification('Clean Cart!!', `Please clean your cart to add "${action.payload.title}".`, 'danger');
                    return{
                        ...state
                    }
                } else {
                    if (action.payload.category.includes("module")) {
                        let check = false;
                        let checedItem = {};
                        state.carts.map((item,key) => {
                            if(item.id === action.payload.id) {
                                // state.carts[key].quantity++;
                                check = true;
                                checedItem = item;
                            }
                            return item;
                        });

                        if(!check){
                            let _cart = {
                                key: action.payload.id,
                                id:action.payload.id,
                                quantity:1,
                                title:action.payload.title,
                                img:action.payload.img,
                                category: action.payload.category,
                                price:action.payload.price,
                                currency: action.payload.currency,
                                selectedSession: action.payload.selectedSession,
                            }

                            const currentCart = state.carts.filter(item=> {
                                if (item.category.includes('module')) {
                                return item;
                                }
                            })
                            
                            if (_cart.category.includes('module')) {
                                if (currentCart.length < 2) {
                                    state.carts.push(_cart);
                                    const msg = currentCart.length === 1 ? `"${_cart.title}" added to the cart successfully` : `"${_cart.title}" added to the cart successfully. Please add one more module to place order`
                                    showNotification('Added To The Cart!!', msg, 'success');
                                    return{
                                        ...state,
                                        numberCart:state.numberCart+1
                                    }
                                } else {
                                    showNotification('Not Allowed!!', 'You can not add more than two modules.', 'warning');
                                    return{
                                        ...state
                                    }
                                }
                                
                            } else {
                                state.carts.push(_cart);
                                showNotification('Added To The Cart!!', `"${_cart.title}" added to the cart successfully.`, 'success');
                                return{
                                    ...state,
                                    numberCart:state.numberCart+1
                                }
                            }
                            
                            
                            
                        } else {
                            showNotification('Already Exists!!', `"${checedItem.title}" already exists in the cart.`, 'info');
                            return{
                                ...state
                            }
                        }
                    } else if(action.payload.category.includes("scholarHybridETraining")) {

                        console.log(state.carts);

                        const duplicateItem = state.carts.find(item => item.id === action.payload.id);

                        

                        if (duplicateItem) {
                            showNotification('Already Exists!!', `"${duplicateItem.title}" already exists in the cart.`, 'info');
                            return{
                                ...state
                            }
                        }

                        const getCountOfItem = state.carts.filter(item => item.category === "scholarHybridETraining");

                        if (getCountOfItem.length >= 3) {
                            showNotification('Not Allowed!!', 'You can not add more than three Certificates.', 'warning');
                                    return{
                                        ...state
                                    }
                        }
                        
                        let cart = {
                            key: action.payload.id,
                            id: action.payload.id,
                            quantity: 1,
                            title: action.payload.title,
                            img: action.payload.img,
                            category: action.payload.category,
                            price: action.payload.price,
                            selectedSession: action.payload.selectedSession,
                            currency: action.payload.currency
                        } 
                        
                        state.carts.push(cart); 
                        const msg = cart.category === 'module' ? `"${cart.title}" added to the cart successfully. Please add one more module to place order` : `"${cart.title}" added to the cart successfully.`
                        showNotification('Added To The Cart!!', msg, 'success');
                        return{
                            ...state,
                            numberCart:state.numberCart+1
                        }
                    } else {
                        showNotification('Already Exists!!', `Your cart is already having one "${action.payload.category}".`, 'danger');
                        return{
                            ...state
                        }
                    }
                }  
            }
            break;
        case INCREASE_QUANTITY:
                state.numberCart++
                state.carts[action.payload].quantity++;
              
               return{
                   ...state
               }
        case DECREASE_QUANTITY:
                let quantity = state.carts[action.payload].quantity;
                if(quantity>1){
                    state.numberCart--;
                    state.carts[action.payload].quantity--;
                }
              
                return{
                    ...state
                }
        case DELETE_CART:
                // let quantity_ = state.carts[action.payload].quantity;
                let deletedItem = {};
                const filteredCart = state.carts.filter(item=> {
                    if (item.id === action.payload) {
                        deletedItem = item;
                    }
                    
                    return item.id !== action.payload
                })
                showNotification('Deleted From The Cart!!', `"${deletedItem.title}" deleted from the cart successfully.`, 'danger');
                return{
                    ...state,
                    numberCart: state.numberCart -1,
                    carts: filteredCart
                   
                }
                case CLEAR_CART:
                    // let quantity_ = state.carts[action.payload].quantity;
                    // let deletedItem = {};
                    // const filteredCart = state.carts.filter(item=> {
                    //     if (item.id === action.payload) {
                    //         deletedItem = item;
                    //     }
                        
                    //     return item.id !== action.payload
                    // })
                    // showNotification('Deleted From The Cart!!', `"${deletedItem.title}" deleted from the cart successfully.`, 'danger');
                    return{
                        ...state,
                        numberCart: 0,
                        carts: []
                       
                    }
        default:
            return state;
    }
}

export const showNotification = (title, message, type) => {
    Store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__flipInx"],
        animationOut: ["animate__animated", "animate__flipOutx"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true
        }
    });
}

const CaftaApp = combineReducers({
    caftaModules: caftaModules
});

export default CaftaApp;