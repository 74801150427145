import React from 'react';
import { Row, Col } from 'antd';
import { Player, BigPlayButton, ControlBar, VolumeMenuButton, ReplayControl, ForwardControl } from 'video-react';
import 'video-react/dist/video-react.css';
import './video-teaser.css';

const VideoTeaser = () => {
    return (
        <Row  className="bg-grey">
            <Col className="container">
                {/* <video width="100%" height="auto" controls autoPlay muted>
                    <source src="videos/Certification_in_Applied_Finance_Treasury_and_Analytics.mp4" type="video/mp4" />
                </video> */}
                <Player  poster="img/vd-teaser-poster.jpg">
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={false} className="my-class">
                        <VolumeMenuButton vertical />
                        <ReplayControl seconds={10} order={2.2} />
                        <ForwardControl seconds={10} order={3.2} />
                    </ControlBar>
                    <source src="videos/Certification_in_Applied_Finance_Treasury_and_Analytics.mp4" />
                </Player>
            </Col>
        </Row>

    );
}

export default VideoTeaser;