import React from "react";
import VideoTeaser from '../video-teaser/video-teaser';
import About from '../about/about';
import Products from '../products/products';
// import Teams from '../teams/teams';
import Contact from '../contact-us/contact'
// import FloatingHeader from './components/header/floating-header';
import { Store } from 'react-notifications-component';
import Testimonial from "../teams/teams";

function Home() {
    // console.log(window.location);
    let myVariable = new URLSearchParams(window.location.search).get('transaction');
    console.log(myVariable);
    if (myVariable) {
        Store.addNotification({
            title: "Trsanction Information",
            message: `Your transaction is ${myVariable}.`,
            type: "info",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__flipInx"],
            animationOut: ["animate__animated", "animate__flipOutx"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                showIcon: true
            }
        });
        setTimeout(() => {
            window.location.replace('/')
        }, 5000);
    }
    return (
        <div>
            {/* <FloatingHeader></FloatingHeader> */}
            <div className="pt-82">
                <About id="aboutSection"></About>
            </div>
            <Products id="productSection"></Products>
            <VideoTeaser></VideoTeaser>
            {/* <About id="aboutSection"></About> */}
            <Testimonial id="teamSection"></Testimonial>
            <Contact id="contactSection"></Contact>
        </div>
    );
}

export default Home;
