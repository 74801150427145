import React, {useState} from "react";
import { Row, Col, Button, Spin, Tabs,  Modal, Radio } from "antd";
import "./module-item.css";
import AppBreadCrumb from '../header/breadcrumb';
import {AddCart} from '../../actions/action'
import {connect} from 'react-redux';

function ModuleItem({moduleItem, AddCart}) {
    const { TabPane } = Tabs;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const radioStyle = {
        lineHeight: '30px',
     };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        console.log(moduleItem);
        AddCart(moduleItem);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    
    const onValueChange = (e) => {
        moduleItem.selectedSession = e.target.value;
    };
    
    return (
        <Row className="pt-82 bg-grey2">
            <Col className="mb-40" span={24}>
                <AppBreadCrumb></AppBreadCrumb>
            </Col>
            <Col className="module-item-min-height" span={22} offset={1}>
                {moduleItem && Object.keys(moduleItem).length ? (
                <Row className="module-item-sec mt-40 mb-40">
                    
                    <Col span={18} className="detail-sec pr-15">
                        <Row className="detail-sec-container">
                            <Col span={6} className="pr-15">
                                <img alt="item " width="100%" src={moduleItem.img}/>
                            </Col>
                            <Col span={18} className="detail-content">
                                <h4 className="item-title">{moduleItem.title}</h4>
                                <p className="sub-title">Language: {moduleItem.lang}</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6} className="add-cart-sec pl-15">
                        <Row className="add-cart-sec-container">
                            <Col span={24}>
                                <p className="small-text">Price including 18% <br/> Taxes</p>
                            </Col>
                            <Col span={24}>
                                <h4 className="item-price">{moduleItem.currency}{moduleItem.price}</h4>
                            </Col>
                            <Col span={24} className="mt-20">
                                <Button onClick={showModal}>ADD TO CART</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={18} className="mt-20 pr-15">
                        <Tabs defaultActiveKey="1" className="course-details">
                            <TabPane tab="ABOUT THIS COURSE" className="about-course" key="1">
                                <p>
                                    {moduleItem.aboutCourse}
                                </p>
                            </TabPane>
                        </Tabs>
                    </Col>
                    
                </Row>
                ) : (
                    <Row>
                        <Col span={24} className="text-center mt-40 mb-40">
                            <Spin tip="Loading..."></Spin>
                        </Col>
                    </Row>
                    
                )}
                <Row className="session-date-modal">
                    <Modal title={moduleItem.title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>Please select the session date</p>
                        <Radio.Group >
                            {
                                Object.keys(moduleItem).length && moduleItem.sessionDates.map((item, i) => (
                                    <Radio key={`${item.date1}-${i}`} style={radioStyle} onChange={($event) => onValueChange($event)} value={item.date1 + ','+ item.date2}>{item.date1} and {item.date2}</Radio>
                                ))
                                
                            }
                            
                        </Radio.Group>
                    </Modal>
                </Row> 
            </Col>
        </Row>
        
    );
}

const mapStateToProps = state => {
    return {
        moduleItem: state.caftaModules.moduleItem
    }
  }
  
export default connect(mapStateToProps, { AddCart })(ModuleItem)
