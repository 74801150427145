import { Auth } from "aws-amplify";
/**
 * Utils: Back-end
 */

import config from '../config'

/**
 * Register a new user
 */
export const userRegister = async (email, password) => {
  return await requestApi('/users/register', 'POST', { email, password })
}

/**
 * Login a new user
 */
export const userLogin = async (email, password) => {
  return await requestApi('/users/login', 'POST', { email, password })
}

/**
 * userGet
 */
export const userGet = async (token) => {
  return await requestApi('/user', 'POST', null, {
    Authorization: `Bearer ${token}`
  })
}

/**
 * Save Profile
 */
export const saveProfile = async (data) => {
  return await requestApi('/profile/create', 'POST', data)
}

/**
 * Get Profile
 */
 export const getProfile = async (email) => {
  return await requestApi('/profile/get', 'POST', email)
}

/**
 * Create Coupon
 */

export const createCoupon = async (data) => {
  return await requestApi('/coupon/create', 'POST', data)
}

export const deleteCoupon = async (data) => {
  return await requestApi('/coupon/delete', 'POST', data)
}


/**
 * Get Coupon Amount
 */
 export const getCouponAmt = async (data) => {
  return await requestApi('/coupon/get', 'POST', data)
}

export const createTransaction = async (data) => {
  return await requestApi('/transaction/create', 'POST', data)
}


export const getAllCoupon = async () => {
  return await requestApi('/coupon/getAll')
}

export const sendEmail = async (data) => {
  return await requestApi('/sendemail', 'POST', data)
}

export const getAllProfile = async (data) => {
  return await requestApi('/reporting/getAllProfile')
}

export const getTransaction = async (data) => {
  return await requestApi('/reporting/getTransaction', 'POST', data)
}


/**
 * API request to call the backend
 */
export const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {}) => {

  // Check if API URL has been set
  if (!config?.domains?.api) {
    throw new Error(`Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "site" folder, in the "./config.js" file.  Instructions are listed there and in the documentation.`)
  }

  // Prepare URL
  if (!path.startsWith('/')) {
    path = `/${path}`
  }
  const url = `${config.domains.api}${path}`

  // Set headers
  headers = Object.assign(
    { 'Content-Type': 'application/json' },
    {Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`},
    headers
  )

  // Default options are marked with *
  const response = await fetch(url, {
    method: method.toUpperCase(),
    // mode: 'cors',
    cache: 'no-cache',
    headers,
    body: data ? JSON.stringify(data) : null
  })

  if (response.status < 200 || response.status >= 300) {
    const error = await response.json()
    throw new Error(error.error)
  }

  if (path.includes('transaction')) {
    return await response.text();
  } else {
    return await response.json()

  }
}