import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import CaftaApp from '../reducers/reducer'
 
const persistConfig = {
  key: 'root',
  storage,
}
 
const persistedReducer = persistReducer(persistConfig, CaftaApp)

const configureStore =  () => {
    let store = createStore(persistedReducer, applyMiddleware(thunkMiddleware))
    let persistor = persistStore(store)
    return { store, persistor }
}

export default configureStore;

// const store =  createStore(CaftaApp,applyMiddleware(thunkMiddleware));
// export default store;