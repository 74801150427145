import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import 'antd/dist/antd.css';
import './index.css';
import './App.module.css';

import Header from './components/header/haeder';
import Home from './components/home/home';
// import Footer from './components/footer/footer';
import ModuleList from './components/module-list/module-list';
import ModuleItem from './components/module-item/module-item';
import SignIn from './components/sign-in/sign-in';
import CartList from './components/cart/cart';
import Scholar from './components/scholar/scholar';
import Admin from './components/admin/admin';
import SkillUpChallenge from './components/skill-up-challenge/skill-up-challenge'
import Executives from './components/executives/executives'
import { AppContext } from './lib/contextLib';
import { Auth } from "aws-amplify";
import { ConfigProvider } from './context/context';
import ScrollToTop from './lib/scrollToTop';
import axios from 'axios';
import FullBatch from './components/executives/FullBatch/fullBatch';
import TwoModules from './components/executives/TwoModules/twoModules';
import HedgeAccounting from './components/HedgeAccounting/hedge-accounting';
import ScholarCertificate from './components/scholar-certificate';
import CaftaCaseChampionship from './components/cafta-case-championship';
import CollegeContactUs from './components/college-webinar-contact-us';
import ForgotPassword from './components/forgot-password/forgot-password';
import FinanceAndTreasuryBootcamp from './components/finance-and-treasury-bootcamp';
import CaftaSprintSeries from './components/cafta-sprint-series';

// import { getSession } from './utils'

// export default class App extends Component {
function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [group, setGroup] = useState("user");
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [configData, setConfigData] = useState({});
  const cancelToken = axios.CancelToken;
  const cancelSource = cancelToken.source();


  const [userDetails, setUserDetails] = useState();

  const getConfigData = () => {
    axios({
        method: 'GET',
        url: `${process.env.PUBLIC_URL}/data/data.json`,
        data: null,
        cancelToken: cancelSource.token
    }).then( res => {
      setConfigData({...res.data});
    }).catch(err => {
    if (axios.isCancel()) {
        return console.log("axios request cancelled");
    }
      return console.log("Catched error: " + err.message);;
    }
    );

  };

  useEffect(() => {
    onLoad();
    getConfigData();
    return () => {
      cancelSource.cancel("axios request cancelled for moduleList fetch");
    };
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      setUserDetails((await Auth.currentAuthenticatedUser()).attributes);
      // console.log((await Auth.currentSession()).getAccessToken().payload['cognito:groups']);
      const groups = (await Auth.currentSession()).getAccessToken().payload['cognito:groups'];

      if (groups && groups.length > 0) {
        // console.log(groups[0]);
        setGroup(groups[0]);
        
      }
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    setIsAuthenticating(false);
    // console.log(group);
  }
  
  return (
    !isAuthenticating && (
      <div id="containerElement" className="app-container">
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          {Object.keys(configData).length && 
            <Router>
              <Header group={group}></Header>
              <ScrollToTop>
              <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return (
                        <Redirect to="/home" />
                      )
                    }}
                  />
                  <Route exact path="/home">
                    <ConfigProvider value={{about: configData.about,
                                            products: configData.products,
                                            teams: configData.teams,
                                            contact: configData.contact}}>
                      < Home></Home>
                    </ConfigProvider>
                  </Route>
                  {/* <Route exact path="/products/skill-up-challenge" >
                    <SkillUpChallenge skillUpChallenge = {{...configData.skillUpChallenge}}></SkillUpChallenge>
                  </Route> */}
                  <Route exact path="/products/scholar">
                    <Scholar scholarInfo = {{...configData.scholarInfo}}></Scholar>
                  </Route>
                  {/* <Route exact path="/products/scholar/cafta">
                    <Batches scholarBatches = {{...configData.scholarInfo.cafta}}></Batches>
                  </Route> */}
                  <Route exact path="/products/scholar/twoModule">
                    <ModuleList twoModules = {{...configData.scholarInfo.twoModules}}></ModuleList>
                  </Route>
                  <Route exact path="/products/scholar/twoModule/:id/:title">
                    <ModuleItem></ModuleItem>
                  </Route>
                  <Route exact path="/products/executives">
                    <Executives scholarInfo = {{...configData.executivesInfo}}></Executives>
                  </Route>
                  <Route exact path="/products/executives/fullBatch">
                    <FullBatch scholarBatches = {{...configData.executivesInfo.fullBatch}}></FullBatch>
                  </Route>
                  <Route exact path="/products/executives/twoModule">
                    <TwoModules twoModules = {{...configData.executivesInfo.twoModules}}></TwoModules>
                  </Route>
                  <Route exact path="/products/hedge-accounting">
                    <HedgeAccounting hedgeAccounting={configData.hedgeAccouting}></HedgeAccounting>
                  </Route>
                  <Route exact path="/products/scholar/cafta">
                    <ScholarCertificate certificateData = {{...configData.caftaScholar}}></ScholarCertificate>
                  </Route>
                  <Route exact path="/products/scholar/cash-and-liquidity">
                    <ScholarCertificate certificateData={configData.cashAndLiquidity}></ScholarCertificate>
                  </Route>
                  <Route exact path="/products/scholar/corporate-finance">
                    <ScholarCertificate certificateData={configData.corporateFinance}></ScholarCertificate>
                  </Route>
                  <Route exact path="/products/scholar/financial-risk-management">
                    <ScholarCertificate certificateData={configData.financialRiskManagement}></ScholarCertificate>
                  </Route>
                  <Route exact path="/products/scholar/investment-risk-management">
                    <ScholarCertificate certificateData={configData.investmentRiskManagement}></ScholarCertificate>
                  </Route>
                  <Route exact path="/products/scholar/supply-chain-and-trade-finance">
                    <ScholarCertificate certificateData={configData.supplyChainAndTradeFinance}></ScholarCertificate>
                  </Route>
                  <Route exact path="/products/scholar/treasury-analytics">
                    <ScholarCertificate certificateData={configData.treasuryAnalytics}></ScholarCertificate>
                  </Route>

                  <Route exact path="/products/cafta-case-championship">
                    <CaftaCaseChampionship certificateData={configData.caftaCaseChampionship}></CaftaCaseChampionship>
                  </Route>

                  <Route exact path="/products/finance-and-treasury-bootcamp">
                    <FinanceAndTreasuryBootcamp
                      data={configData.financeAndTreasuryBootcamp}
                    />
                  </Route>

                  <Route exact path="/products/cafta-sprint-series">
                    <CaftaSprintSeries certificateData={configData.caftaSprintSeries}/>
                  </Route>

                  <Route exact path="/contact-us">
                    <CollegeContactUs></CollegeContactUs>
                  </Route>
                  

                  ({!isAuthenticated && <Route exact path="/sign-in">
                    <SignIn></SignIn>
                  </Route>})
                  ({!isAuthenticated && <Route exact path="/forgot-password">
                    <ForgotPassword/>
                  </Route>})
                  <Router exact path="/my-cart">
                    <CartList userDetails={userDetails}></CartList>
                  </Router>
                  <Router exact path="/admin">
                    <Admin userDetails={userDetails}></Admin>
                  </Router>
                  <Route path="*">
                    <ConfigProvider value={{about: configData.about,
                                            products: configData.products,
                                            teams: configData.teams,
                                            contact: configData.contact}}>
                        <Home></Home>
                    </ConfigProvider>
                  </Route>
                  {/* <PrivateRoute
                      exact
                      path='/'
                      component={Dashboard}
                    /> */}
                </Switch>
              </ScrollToTop>
              <footer className="text-center app-footer">
                <span>© Ernst & Young</span>
              </footer>
              {/* <Footer footer = {[...configData.footer]}></Footer> */}
            </Router>
          
          }
          
        </AppContext.Provider>
      </div>
    )
  )
  // }
}
export default App;
/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
// const PrivateRoute = ({ component, ...options }) => {

//   const session = getSession()

//   const finalComponent = session ? Dashboard : Home
//   return <Route {...options} component={finalComponent} />
// }