/**
 * Global Config
 */

const config = {
    cognito: {
        USER_POOL_ID: "us-east-2_UATXEv7s6",
        APP_CLIENT_ID: "4dq3okcnuig2r90s30602sl0io",
      }
}

// Domains
config.domains = {}

/**
 * API Domain
 * Add the domain from your serverless express.js back-end here.
 * This will enable your front-end to communicate with your back-end.
 * (e.g. 'https://api.mydomain.com' or 'https://091jafsl10.execute-api.us-east-1.amazonaws.com')
 */
config.domains.api = 'https://jvsb36j3pb.execute-api.us-east-1.amazonaws.com/dev'

export default config