import React, { useState, useEffect } from "react";
import { Row, Col, Button, Empty, Spin, Table } from "antd";
import { PushpinOutlined } from "@ant-design/icons";
import {
  useParams
} from "react-router-dom";
import "./fullBatch.css";
import AppBreadCrumb from '../../header/breadcrumb';
import { AddCart } from '../../../actions/action'
import { connect } from 'react-redux';
import { useHistory } from "react-router";


const FullBatch = ({ scholarBatches, AddCart }) => {

  const { type } = useParams();

  const [activeCardIndex, setCardIndex] = useState(0);

  const history = useHistory();

  const [comboItem] = useState({
    key: "comboProduct1",
    id: "comboProduct1",
    title: "Combo Product 1",
    category: "Hedge accounting + Executive CAFTA",
    price: 25000,
    currency: "INR",
    img: "http://study.com/cimages/course-image/praxis-ii-business-education-test_118084_large.jpg"
})


  const navTo = (link) => {
      history.push(link);
    }

  const columns = [
    {
      title: 'Module',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '1st Webinar Date',
      dataIndex: 'webinar1',
      key: 'webinar1',
    },
    {
      title: '2nd Webinar Date',
      dataIndex: 'webinar2',
      key: 'webinar2',
    },

  ];

  const openBatchCard = (index) => {
    setCardIndex(index);
  }

  useEffect(() => {
    console.log(scholarBatches);
  }, [])


  return (
    <Row className="pt-82 bg-grey2">
      <Col className="mb-40" span={24}>
        <AppBreadCrumb></AppBreadCrumb>
      </Col>
      <Col span={22} offset={1}>
        {scholarBatches && Object.keys(scholarBatches).length ? (
          <Row className="batch-sec mt-40 mb-40">
            <Col span={17} className="batch-detail-sec-container pr-15">
              <Row className="batch-detail-sec-wrapper ">
                <Col span={24} className="batch-title mb-20">
                  <h3 >CAFTA Corporate : Hybrid E-Learning Program</h3>
                </Col>
                <Col span={24} className="batch-description mb-20 mt-20">
                  <p>{scholarBatches.description.p1}</p>
                </Col>
              </Row>
            </Col>

            <Col span={7} className="pl-15 batch-details-sec">
              <Row className="batch-other-details-sec-wrapper">
                <Col span={24} className="mb-20">
                  <h4>Registration Details:</h4>
                </Col>

                <Col span={24}>
                  {/* <div className="fw-600">Live webinar timing:</div>
                  <div>Saturday and Sunday, 10:30AM to 12:30PM</div> */}
                  <div className="fw-600">Expected Audience:</div>
                  <div>Junior to mid-management professionals</div>
                  <div className="fw-600">Product Fee:</div>
                  <div>INR 21,000* (for any available offers, write to <a href="mailto:cafta@in.ey.com">cafta@in.ey.com</a>)</div>
                  <div>*Fee exclusive of 18% GST</div>

                  {/* <div style={{marginTop: '15px'}}>
                    <span>Add Certificate in Treasury Hedge Accounting at a discount of 50% as a combo with the full CAFTA course</span><Button type="" href="/products/hedge-accounting">View Course Details</Button>
                  </div> */}
                  <div className="combo-class">
                    <span>Add Certificate in Treasury Hedge Accounting at a discount of 50% as a combo with the full CAFTA course</span>
                    <div className="text-center width-100"><Button type="primary" href="/products/hedge-accounting">View Certificate in Treasury Hedge Accounting</Button></div>
                    <div  className="full-combo-btn text-center"><Button onClick={() => AddCart(comboItem)}>Add Combo</Button></div>
                  </div>
                  {/* {twoModules.otherDetails.length ? (
                                    twoModules.otherDetails.map((item, i) => (
                                        <div  key={`${item.item}-${i}`}  className="mb-10">
                                            <div className="fw-600">{item.text}</div>
                                            <div>{item.value}</div>
                                        </div>
                                    ))
        
                                    ) : (
                                        <Col span={24} className="text-center mt-40 mb-40">
                                            <Empty />
                                        </Col>
                                )} */}

                </Col>
                {/* <Col span={24}>
                                {scholarBatches.otherDetails.length ? (
                                    scholarBatches.otherDetails.map((item, i) => (
                                        <div key={`${item.text}-${i}`}  className="mb-10">
                                            <div className="fw-600">{item.text}</div>
                                            {
                                                item.text === "Upcoming batches:  " ? (
                                                    item.value.map((val, ind) => (
                                                        <div key={`${val}-${ind}`}>{val}</div>
                                                    ))
                                                ) : (
                                                    <div>{item.value}</div>
                                                )
                                            }
                                            
                                        </div>
                                    ))
        
                                    ) : (
                                        <Col span={24} className="text-center mt-40 mb-40">
                                            <Empty />
                                        </Col>
                                )}
                                
                            </Col> */}
              </Row>
            </Col>

            <Col span={24} className="mt-20 module-highlight-wrapper">
              <Row>
                <Col span={24} className="module-highlight-title pr-15 pl-15 mt-5 mb-20">
                  <h3 >Program highlights</h3>
                </Col>
                <Col span={24} className="mt-20 mb-20">
                  <Row>
                    <Col span={24} className="pr-15 pl-15">
                      <p>{scholarBatches.description.progHighlights.heading}</p>
                      <ul>
                        {scholarBatches.description.progHighlights.list.length ? (
                          scholarBatches.description.progHighlights.list.map((item, i) => (
                            <li key={`${item.text}-${i}`}>
                              <span className="fw-600">{item.text}</span> {item.value}
                            </li>
                          ))

                        ) : (
                          <Col span={24} className="text-center mt-40 mb-40">
                            <Empty />
                          </Col>
                        )}
                      </ul>
                      {/* <i><b>*Available as per performance in CAFTA assessment</b></i> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24} className="mt-20 module-highlight-wrapper">
              <Row>
                <Col span={24} className="module-highlight-title pr-15 pl-15 mt-5 mb-20">
                  <h3 >Explore Our Modules</h3>
                </Col>
                <Col span={24} className="mt-20 mb-20">
                  <Row>
                    <Col span={24} className="pr-15 pl-15">
                      <p>{scholarBatches.description.understandModule.heading}</p>
                      <ol type="1">
                        {scholarBatches.description.understandModule.list.length ? (
                          scholarBatches.description.understandModule.list.map((item, i) => (
                            <li key={`${item.text}-${i}`}>
                              <span className="fw-600">{item.text}</span> {item.value}
                            </li>
                          ))

                        ) : (
                          <Col span={24} className="text-center mt-40 mb-40">
                            <Empty />
                          </Col>
                        )}
                      </ol>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24} className="mt-20 module-highlight-wrapper">
              <Row>
                <Col span={24} className="module-highlight-title pr-15 pl-15 mt-5 mb-20">
                  <h3 >Program Benefits</h3>
                </Col>
                <Col span={24} className="mt-20 mb-20">
                  <Row>
                    <Col span={24} className="pr-15 pl-15">
                      <p>{scholarBatches.description.programBenefits.heading}</p>
                      <ul>
                        {scholarBatches.description.programBenefits.list.length ? (
                          scholarBatches.description.programBenefits.list.map((item, i) => (
                            <li key={`${item.value}-${i}`}>
                              {/* <span className="fw-600">{item.text}</span>  */}
                              {item.value}
                            </li>
                          ))

                        ) : (
                          <Col span={24} className="text-center mt-40 mb-40">
                            <Empty />
                          </Col>
                        )}
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>


            <Col span={24} className="mt-20 batch-sec-wrapper">
              <Row>
                <Col span={24} className="batch-title mb-20">
                  <h3 >Batches</h3>
                </Col>
                <Col span={24} className="mt-20 mb-20">
                  {scholarBatches.batchList.length ?
                    (scholarBatches.batchList.map((item, i) => (
                      <div key={`${item.id}-${i}`} className={activeCardIndex === i ? 'hover-card card' : 'card'}>
                        <div className="flip-card">
                          <div className="flip-card__container">
                            <div className="card-front">
                              <div className="card-front__top">
                                {type}

                                <h2 className="card-front__heading">
                                  {item.title}
                                </h2>
                                {
                                  (item.price !== 0) && <p className="card-front__text-price">
                                    Price {item.price} {item.currency}
                                  </p>
                                }
                              </div>

                              <div className="card-front__bt">
                                {
                                  item.moduleList.length ? (
                                    <p className="card-front__text-view card-front__text-view--city">
                                      View Full batch
                                    </p>
                                  ) : (
                                    <p className="card-front__text-view card-front__text-view--city">
                                      Coming Soon
                                    </p>
                                  )
                                }

                                {item.moduleList.length && <PushpinOutlined onClick={() => openBatchCard(i)} className="pin-btn" />}
                              </div>
                            </div>
                            <div className="card-back">
                              <div className="card-back__container">
                                <div className="pl-10 pr-10 mb-10">
                                  <span>{item.title}</span>
                                  <Button onClick={() => AddCart(item)} className="add-batch-cart-btn pull-right">Add Full Batch To Cart</Button>
                                  {/* <Button className="view-module-btn pull-right mr-15">
                                                                    <NavLink to={'/products/' + type + '/' + item.title + '/' + item.id}>
                                                                         View Modules
                                                                    </NavLink>
                                                                </Button> */}
                                </div>
                                <div className="pl-10 pr-10">
                                    {item?.description?.map((desc, index) => (
                                    <div key={index}>
                                      <p className="mb-10">{desc.title}</p>
                                      <ul>
                                        {desc.description?.map(para => (<li>{para}</li>))}
                                      </ul>
                                    </div>
                                    ))}
                                </div>
                                {/* <Table dataSource={item.moduleList} columns={columns} pagination={{ position: ['none', 'none'] }} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    ) : (
                      <Empty />
                    )
                  }
                </Col>
              </Row>
            </Col>

            {/* <Col span={24} className="mt-20 module-highlight-wrapper">
                            <Row>
                                <Col span={24} className="module-highlight-title pr-15 pl-15 mt-5 mb-20">
                                    <h3 >LIVE Batch Schedule</h3>
                                </Col>
                                <Col span={24} className="mt-20 mb-20">
                                    <Row>
                                        <Col span={24} className="pr-15 pl-15">
                                            <p>{scholarBatches.description.programBenefits.heading}</p>
                                            <div className="card-back">
                                                <div className="card-back__container">
                                                    <div className="pl-10 pr-10 mb-10">
                                                        <span>{item.title}</span>
                                                        <Button onClick={() => AddCart(item)} disabled={item.id === 'batch1'} className="add-batch-cart-btn pull-right">Add Full Batch To Cart</Button>

                                                    </div>
                                                    <Table dataSource={item.moduleList} columns={columns} pagination={{ position: ['none', 'none'] }} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col> */}


            {/* <Col span={24} className="pr-15 pl-15">
                                        <p>{scholarBatches.description.understandModule.heading}</p>
                                        <ol type="1">
                                            {scholarBatches.description.understandModule.list.length ? (
                                                scholarBatches.description.understandModule.list.map((item, i) => (
                                                    <li  key={`${item.text}-${i}`}>
                                                        <span className="fw-600">{item.text}</span> {item.value}
                                                    </li>
                                                ))
                    
                                                ) : (
                                                    <Col span={24} className="text-center mt-40 mb-40">
                                                        <Empty />
                                                    </Col>
                                            )}      
                                        </ol>
                                    </Col> */}

            {/* </Row> */}
            {/* //         </Col> */}
            {/* //     </Row> */}
            {/* // </Col> */}

            {/* <Col span={24} className="mt-20 batch-sec-wrapper">
                        <Row>
                            <Col span={24} className="batch-title mb-20">
                                <h3 >Batches</h3>
                            </Col>
                            <Col span={24} className="mt-20 mb-20">
                                { scholarBatches.batchList.length ?
                                    (scholarBatches.batchList.map((item, i) => (
                                        <div  key={`${item.id}-${i}`}  className={activeCardIndex === i ? 'hover-card card' : 'card'}>
                                            <div className="flip-card">
                                                <div className="flip-card__container">
                                                    <div className="card-front">
                                                        <div className="card-front__top">
                                                            {type}

                                                            <h2 className="card-front__heading">
                                                                {item.title}
                                                            </h2>
                                                            {
                                                               (item.price !== 0) && <p className="card-front__text-price">
                                                                                Price {item.price} {item.currency}
                                                                            </p>
                                                            }
                                                        </div>

                                                        <div className="card-front__bt">
                                                            {
                                                                item.moduleList.length ? (
                                                                    <p className="card-front__text-view card-front__text-view--city">
                                                                        View Full batch
                                                                    </p>
                                                                ) : (
                                                                    <p className="card-front__text-view card-front__text-view--city">
                                                                        Coming Soon
                                                                    </p>
                                                                )
                                                            }
                                                            
                                                            { item.moduleList.length && <PushpinOutlined onClick={() => openBatchCard(i)} className="pin-btn" />}
                                                        </div>
                                                    </div>
                                                    <div className="card-back">
                                                        <div className="card-back__container">
                                                            <div className="pl-10 pr-10 mb-10">
                                                                <span>{item.title}</span>
                                                                <Button onClick={()=>AddCart(item)} disabled={item.id === 'batch1'} className="add-batch-cart-btn pull-right">Add Full Batch To Cart</Button>

                                                            </div>
                                                            <Table dataSource={item.moduleList} columns={columns} pagination={{ position: ['none', 'none'] }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))
                                    ) : (
                                        <Empty />
                                    ) 
                                }
                            </Col>
                        </Row>
                    </Col> */}

          </Row>
        ) : (
          <Row>
            <Col span={24} className="text-center mt-40 mb-40">
              <Spin tip="Loading..."></Spin>
            </Col>
          </Row>

        )}
      </Col>
    </Row>

  );
}


export default connect(null, { AddCart })(FullBatch)
