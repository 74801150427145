import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin, Table, Button } from "antd";
import "./hedge-accounting.css";
import AppBreadCrumb from '../header/breadcrumb';
import { AddCart } from '../../actions/action'
import { connect } from 'react-redux';
import { useHistory } from "react-router";


const camelCaseToNormal = (camelString) => {
    return camelString.replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, (str) => (str.toUpperCase()))
}

const HedgeAccounting = ({ hedgeAccounting, AddCart }) => {
    const { Meta } = Card;
    const [addItem, setAddItem] = useState({});
    const [comboItem] = useState({
        key: "comboProduct1",
        id: "comboProduct1",
        title: "Combo Product 1",
        category: "Hedge accounting + Executive CAFTA",
        price: 25000,
        currency: "INR",
        img: "http://study.com/cimages/course-image/praxis-ii-business-education-test_118084_large.jpg"
    })
    const history = useHistory();


    const navTo = (link) => {
        history.push(link);
      }

    useEffect(() => {
        const temp = {
            key: hedgeAccounting.key,
            id: hedgeAccounting.id,
            title: hedgeAccounting.title,
            category: hedgeAccounting.category,
            price: hedgeAccounting.price,
            currency: hedgeAccounting.currency,
            img: hedgeAccounting.img
        }
        setAddItem(temp);
    }, []);



    return (
        <Row className="pt-82 bg-grey2">
            <Col className="mb-40" span={24}>
                <AppBreadCrumb></AppBreadCrumb>
            </Col>
            <Col span={22} offset={1}>
                {hedgeAccounting && Object.keys(hedgeAccounting).length ? (

                    <Row gutter={[24, 24]} className="skill-up-challenge-sec mt-40 mb-40">
                        {hedgeAccounting.description.cards.map(item => (
                            <Col md={24} lg={item.col} className="description-sec">
                                <Row className="description-sec-wrapper">
                                    <Col span={24} className="sec-title mb-20">
                                        <h3 >{item.heading}</h3>
                                    </Col>
                                    <Col span={24} className="about-challenge mt-20">
                                        {Object.keys(item).map(obj => {
                                            return ((obj !== "addToCart" && obj !== "table" && obj !== "col" && obj !== "heading") ? (
                                                <>
                                                    <p className="fw-600">{camelCaseToNormal(obj)}</p>
                                                    <ul>
                                                        {item[obj].map(list => (
                                                            <li>{list}</li>
                                                        ))}
                                                    </ul>
                                                </>) : obj === "table" ?
                                                            (<div className="hedge-accounting-table"><Table bordered dataSource={item[obj].dataSource} columns={item[obj].columns} pagination={{ position: ['none', 'none'] }}/></div> )
                                                : obj === "addToCart" ? (
                                                <div className="">
                                                    <div className="hedge-accounting-btn text-center mb-10">
                                                        <Button onClick={() => AddCart(addItem)}>Add To Cart</Button>
                                                    </div>
                                                    <div className="combo-class">
                                                        <span>{item[obj]}</span>
                                                        <div className="text-center width-100"><Button type="primary" href="/products/executives/fullBatch" >View CAFTA Executive Virtual Program Details</Button></div>
                                                        <div className="full-combo-btn text-center"><Button onClick={() => AddCart(comboItem)}>Add Combo</Button></div>
                                                    </div>
                                                </div>
                                                ) : ""

                                            )
                                        })}

                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Row>
                        <Col span={24} className="text-center mt-40 mb-40">
                            <Spin tip="Loading..."></Spin>
                        </Col>
                    </Row>

                )}
            </Col>
        </Row>

    );
}
// export default HedgeAccounting;
export default connect(null, { AddCart })(HedgeAccounting)