import React, { useContext } from "react";
import { Row, Col, Spin } from "antd";
import {
  NavLink
} from "react-router-dom";
import "./products.css";
import { ConfigContext } from '../../context/context';
import { Store } from 'react-notifications-component';


function Products() {
  const {products} = {...useContext(ConfigContext)};

  const showNotification = () => {
    Store.addNotification({
        title: "Not Available!!",
        message: "COMING SOON",
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__flipInx"],
        animationOut: ["animate__animated", "animate__flipOutx"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true
        }
    });
  }
  
  return (
    <Row id="productSection" className="products-sec products mt-40">
      {products && products.length ? (
        <Col span={24} className="text-center">
          <Row className="section-title">
            <Col className="mb-20"  span={24}>
              <h3>Our Products</h3>
            </Col>
          </Row>
          <Row className="mt-40">
            {products.map((d, i) => (
              <Col className="product-tile" span={8} key={`${d.name}-${i}`}>
                {/* {" "}
                {d.name !== "CAFTA Executives" &&  <NavLink  to={d.route}>
                  <i className={d.icon}></i>
                </NavLink>}
                {d.name === "CAFTA Executives" &&  <div  onClick={showNotification}>
                  <i className={d.icon}></i>
                </div>} */}
                <NavLink  to={d.route}>
                  <i className={d.icon}></i>
                </NavLink>
                <div className="product-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      ) : (
        <Col span={24} className="text-center mt-40 mb-40">
            <Spin tip="Loading..."></Spin>
        </Col>
      )}
    </Row>
  );
}

export default Products;
