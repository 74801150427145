import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import { Provider } from "react-redux";
import configureStore from "./store/store";
import { PersistGate } from 'redux-persist/integration/react'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Amplify } from 'aws-amplify';
import config from './config';

/**
 * Render App
 */

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    // region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    // identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

const { store, persistor } = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactNotifications />
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
      
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)