import React, { useContext } from "react";
import { Row, Col } from "antd";
import "./contact.css";
import { ConfigContext } from '../../context/context';

function Contact() {
  const {contact} = {...useContext(ConfigContext)};

  return (
    <Row id="contactSection" className="contact-sec mt-40">
        <Col span={15} offset={1} className="">
            <Row className="">
                <Col span={24} className="section-title">
                  <h3>Get In Touch</h3>
                  {/* <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p> */}
                </Col>
                {/* <Col span={24}>
                    <form   noValidate>
                        <Row className="">
                            <Col span={24} className="">
                                <div className="form-group">
                                    <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    required="required"
                                    />
                                </div>
                            </Col>
                            <Col span={24} className="">
                                <div className="form-group">
                                    <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    required="required"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="form-group">
                            <textarea
                            name="message"
                            className="form-control"
                            rows="4"
                            placeholder="Message"
                            required
                            ></textarea>
                        </div>
                        <button className="btn btn-custom btn-lg">
                            Send Message
                        </button>
                    </form>
                </Col> */}
            </Row>
        </Col>
        <Col span={6} offset={2} className=" contact-info">
            {/* <div className="contact-item">
                <h4>Contact Info</h4>
                <p>
                    <span>
                    <i className="fa fa-map-marker"></i> Address
                    </span>
                    {contact && contact.length ? contact[0].address : "loading"}
                </p>
            </div> */}
            <div className="contact-item">
                <p>
                    <span>
                    <i className="fa fa-phone"></i> Phone
                    </span>{" "}
                    Priya Bathla: <a href="tel:+917303943512">+917303943512</a><br></br>
                    Sanjana Bhola: <a href="tel:+917303943513">+917303943513</a>
                    {/* {contact && contact.length ? (contact[0].phone) : "loading"} */}
                </p>
            </div>
            <div className="contact-item">
                <p>
                    <span>
                    <i className="fa fa-envelope-o"></i> Email
                    </span>{" "}
                    {contact && contact.length ? <a href="mailto:cafta@in.ey.com">cafta@in.ey.com</a> : "loading"}
                </p>
            </div>
        </Col>
    </Row>
  );
}

export default Contact;
