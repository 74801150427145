import React, {  useState } from "react";
import { Row, Col, Card, Button, Spin, Modal, Radio, Empty } from "antd";
import {
    NavLink,
    useParams
  } from "react-router-dom";
import "./module-list.css";
import AppBreadCrumb from '../header/breadcrumb';
import { AddCart } from '../../actions/action'
import { connect, useDispatch } from 'react-redux';
import moment from "moment";


function ModuleList({twoModules, AddCart}) {
    const { type, batchName } = useParams();
    const { Meta } = Card;
    const radioStyle = {
       lineHeight: '30px',
    };
    const dispatch = useDispatch()

    let moduleList = twoModules.moduleList;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [moduleItem, setmoduleItem] = useState({});
    
    const showModal = (item) => {
        setmoduleItem(item);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        AddCart(moduleItem);
    };

    const handleCancel = () => {
        setmoduleItem({});
        setIsModalVisible(false);
    };
    
    const onValueChange = (e, item) => {
        console.log(moment(new Date()).format("DD/MM/YYYY"));
        moduleList = moduleList.map((itm, i) => {
            if (itm.id === item.id) {
                itm.selectedSession = e.target.value;
                setmoduleItem(itm);
            }
            return item;
        })
    };

    const dispatchItem = (item) => {
        dispatch({type: 'GET_MODULE_ITEM', payload: item })
    }
    
     
    return (
        <Row className="pt-82 bg-grey2">
            <Col className="mb-40" span={24}>
                <AppBreadCrumb></AppBreadCrumb>
            </Col>
            <Col span={22} offset={1}>
            {twoModules && Object.keys(twoModules).length ? (
                <Row className="two-modules-sec mt-40 mb-40">
                    <Col span={17} className="modules-sec-container pr-15">
                        <Row className="module-sec-wrapper">
                            <Col span={24} className="two-modules-title mt-5 pr-15 pl-15 mb-20">
                                <h3 >CAFTA Two Modules</h3>
                            </Col>
                            <Col span={24} className="two-modules-description pr-15 pl-15 mt-20">
                                <p>{twoModules.description.p1}</p>
                                <p>{twoModules.description.p2}</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={7} className="pl-15 two-modules-details-sec">
                        <Row className="two-modules-other-details-sec-wrapper">
                            <Col span={24} className="mb-20">
                                <h4>Other Program Details</h4>
                            </Col>
                            <Col span={24}>
                                {twoModules.otherDetails.length ? (
                                    twoModules.otherDetails.map((item, i) => (
                                        <div  key={`${item.item}-${i}`}  className="mb-10">
                                            <div className="fw-600">{item.text}</div>
                                            <div>{item.value}</div>
                                        </div>
                                    ))
        
                                    ) : (
                                        <Col span={24} className="text-center mt-40 mb-40">
                                            <Empty />
                                        </Col>
                                )}
                                
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24} className="mt-20 module-highlight-wrapper">
                        <Row>
                            <Col span={24} className="module-highlight-title pr-15 pl-15 mt-5 mb-20">
                                <h3 >Program highlights</h3>
                            </Col>
                            <Col span={24} className="mt-20 mb-20">
                                <Row>
                                    <Col span={24} className="pr-15 pl-15">
                                        <p>{twoModules.description.progHighlights.heading}</p>
                                        <ul>
                                            {twoModules.description.progHighlights.list.length ? (
                                                twoModules.description.progHighlights.list.map((item, i) => (
                                                    <li  key={`${item.text}-${i}`}>
                                                        <span className="fw-600">{item.text}</span> {item.value}
                                                    </li>
                                                ))
                    
                                                ) : (
                                                    <Col span={24} className="text-center mt-40 mb-40">
                                                        <Empty />
                                                    </Col>
                                            )}      
                                        </ul>
                                    </Col>

                                    <Col span={24} className="pr-15 pl-15">
                                        <p>{twoModules.description.understandModule.heading}</p>
                                        <ol type="1">
                                            {twoModules.description.understandModule.list.length ? (
                                                twoModules.description.understandModule.list.map((item, i) => (
                                                    <li  key={`${item.text}-${i}`}>
                                                        <span className="fw-600">{item.text}</span> {item.value}
                                                    </li>
                                                ))
                    
                                                ) : (
                                                    <Col span={24} className="text-center mt-40 mb-40">
                                                        <Empty />
                                                    </Col>
                                            )}      
                                        </ol>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col span={24} className="mt-20 module-list-wrapper">
                        <Row>
                            <Col span={24} className="module-list-title pr-15 pl-15 mt-5 mb-20">
                                <h3 >Live E-Trainings</h3>
                            </Col>
                            <Col span={24} className="mt-20">
                                <Row  className="module-list-item mb-40">
                                    {moduleList && moduleList.length ? (
                                        moduleList.map((item, i) => (
                                            <Col  key={`${item.title}-${i}`} span={4} className="pr-15 pl-15 mb-20 card-container">
                                                <Card
                                                    className="front-card"
                                                    hoverable
                                                        cover={<NavLink onClick={() => dispatchItem(item)} to={'/products/scholar/twoModule/' + item.id + '/' + item.title}>
                                                                    <img alt="Course" src={item.img} />
                                                                </NavLink>
                                                        }>
                                                        <Meta title={item.title} description={
                                                            <Row>
                                                                <Col span={24} className="lang-info">LANGUAGE: {item.lang}</Col>
                                                                <Col span={24}>
                                                                    <Button onClick={() => showModal(item)} className="add-to-cart-btn">ADD TO CART</Button>
                                                                </Col>
                                                            </Row>
                                                        } />
                                                </Card>
                                            </Col>
                                    ))
                        
                                    ) : (
                                        <Col span={24} className="text-center mt-40 mb-40">
                                                <Empty />
                                            </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col span={24} className="text-center mt-40 mb-40">
                        <Spin tip="Loading..."></Spin>
                    </Col>
                </Row>
                
            )}    
                <Row className="session-date-modal">
                    <Modal title={moduleItem.title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>Please select the session date</p>
                        <Radio.Group >
                            {
                                Object.keys(moduleItem).length && moduleItem.sessionDates.map((item, i) => (
                                    <Radio key={`${item.date1}-${i}`} style={radioStyle} onChange={($event) => onValueChange($event,moduleItem)} value={item.date1 + ','+ item.date2}>{item.date1} and {item.date2}</Radio>
                                ))
                                
                            }
                            
                        </Radio.Group>
                    </Modal>
                </Row>
            </Col>
        </Row>
        
    );
}


export default connect(null,{AddCart})(ModuleList)
