import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Table, Button, Modal, Form, Input, Select, Checkbox, Steps } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import "./cart.css";
import { DeleteCart, ClearCart } from '../../actions/action'
import { connect } from 'react-redux';
import { createTransaction, saveProfile, getProfile, getCouponAmt } from '../../utils/api';
import { useAppContext } from "../../lib/contextLib";
import Item from "antd/lib/list/Item";
import { showNotification } from '../../reducers/reducer';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Text from "antd/lib/typography/Text";

const { Step } = Steps;

// const viewTAndC = () => {
//   const docs = [
//     { uri: '/data/CAFTA_Terms and conditions.docx' },
//   ];
 
//   return <DocViewer documents={docs} />;
// }

function CartList({ items, DeleteCart, ClearCart, userDetails }) {

  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const { Option } = Select;
  const [form] = Form.useForm();

  const [form2] = Form.useForm();
  const { Search } = Input;
  const couponRef = useRef(null);
  const [payNowLoading, setPayNowLoading] = useState(false);

  const [transactionLoading, setTransactionLoading] = useState(false);

  const [steps, setSteps] = useState([

  ]);

  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const [totalCart, setTotalCart] = useState(0);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Image',
      dataIndex: 'img',
      key: 'img',
      render: (img) => (
        <>
          {
            img ? (
              <img alt="product" width="150px" height="80px" src={img} />
            ) :
              ""
          }
        </>

      )
    },
    // {
    //   title: 'Price',
    //   key: 'price',
    //   render: (price, record) => (
    //     <>
    //       {
    //         record.category === "module" ? (
    //           <span>INR 7500 (Two module cost)</span>
    //         ) : 
    //         (
    //           <span>{record.currency} {record.price}</span>
    //         )
    //       }
    //     </>
    //   ),
    // },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <a href="#" className="delete-item" onClick={() => DeleteCart(record.id)}>
          <DeleteOutlined />
        </a>
      ),
    },
  ];

  useEffect(() => {
    totalCartPrice();
  }, [])

  useEffect(() => {
    if (couponCode) {
      applyCoupon(couponCode);
    }
  }, [totalCart])

  // let totalCart = 0;
  // console.log(items);

  const totalCartPrice = () => {
    // console.log((await Auth.currentSession()).getAccessToken().getJwtToken());
    let checkModule = false;
    let executiveCheckModule = false;
    let scholarHybridETraining = false;
    items.forEach(function (item) {
      if (item.category === "module") {
        checkModule = true;
      } else if (item.category === "executive-module") {
        executiveCheckModule = true;
      } else if (item.category === "scholarHybridETraining") {
        scholarHybridETraining = true;
      } else {
        // totalCart += item.price;
        setTotalCart(totalCart + item.price);
      }
    });
    if (checkModule) {
      setTotalCart(totalCart + 7500);
      // totalCart = totalCart + 7500;
    } else if (executiveCheckModule) {
      setTotalCart(totalCart + 8500);
      // totalCart = totalCart + 8500;
    } else if (scholarHybridETraining) {
      if (items.length === 1) {
        setTotalCart(3000);
        // totalCart = 3000;
      } else if (items.length === 2) {
        setTotalCart(5500);
        // totalCart = 5500;
      } else if (items.length === 3) {
        setTotalCart(7500);
        // totalCart = 7500;
      }
    }
    // console.log(totalCart);

  };


  // totalCartPrice();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        console.log(values);
        // TODO:
        onFinish(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const applyCoupon = async (value) => {
    let product = "";
    if (items[0].id.includes("batch")) {
      product = "scholar-batch";
    } else if (items[0].id.includes("skillUpChallenge")) {
      product = "skillUpChallenge";
    } else if (items[0].id.includes("hedgeAccouting")) {
      product = "hedgeAccounting";
    } else if (items[0].id.includes("executiveBatch1")) {
      product = "caftaExecutiveBatch";
    } else if (items[0].id.includes("comboProduct1")) {
      product = "comboProduct1";
    } else if (items[0].category.includes("scholarHybridETraining")) {
      product = "scholarHybridETraining";
    } else if (items[0].category.includes("caftaCaseChampionship")) {
      product = "caftaCaseChampionship";
    } else if (items[0].category.includes("caftaScholar")) {
      product = "scholar-batch";
    } else if (items[0].id.includes("financeAndTreasuryBootcamp")) {
      product = "financeAndTreasuryBootcamp";
    } else if (items[0].category.includes("caftaSprintSeries")) {
      product = "caftaSprintSeries";
    }

    if (!value) return;
    try {
      const couponDetail = await getCouponAmt({ code: value, product: product })
      console.log(couponDetail);
      if (typeof couponDetail.message !== 'string') {
        if (!parseFloat(couponDetail.message.couponPercent)) {
          console.log('couponPercent');
          setCouponDiscount(parseFloat(couponDetail.message.couponPrice))
        } else {
          console.log('couponPrice');
          const discount = (totalCart * parseFloat(couponDetail.message.couponPercent)) / 100;
          console.log(discount);
          setCouponDiscount(discount);
        }
        setCouponCode(value);
        showNotification('Coupon Applied!!', 'Coupon Applied Successfully', 'success');
      } else {
        showNotification('Invalid!!', couponDetail.message, 'warning');
      }

    } catch (e) {
      showNotification('Error!!', e.message, 'danger');
    }

  };

  const removeCoupon = () => {
    setCouponCode("");
    setCouponDiscount(0);
    couponRef.current.state.value = "";
    showNotification('Coupon Removed!!', 'Coupon Removed Successfully', 'danger');
  }

  const payNow = async () => {

    if (!isAuthenticated) {
      showNotification('Error!!', 'Please sign in with the currently active account!!.', 'danger');
      return;
    }

    const noOfModule = items.filter(item => {
      if (item.category === 'module') {
        return item;
      }
    })

    const caftaCaseChampionship = items.filter(item => item.category === 'caftaCaseChampionship');
    // console.log(noOfModule);
    if (noOfModule.length && noOfModule.length < 2) {
      showNotification('Add More Module!!', 'Add one more module to place order..', 'warning');
      return;
    } else {
      setPayNowLoading(true);
      try {
        const profile = await getProfile({ email: userDetails.email })
        if (typeof profile.message !== 'string') {

          form.setFieldsValue(profile.message);
        }
        setPayNowLoading(false);
        showModal();
      } catch (e) {
        setPayNowLoading(false);
        console.log(e);

      }
    }
  }

  const onFinish = async (values) => {

    setTransactionLoading(true);

    try {
      await saveProfile(
        {
          latestDegree: values.latestDegree,
          firstName: values.firstName,
          gradYear: values.gradYear,
          hearFrom: values.hearFrom,
          institution: values.institution,
          lastName: values.lastName,
          linkedInProfile: values.linkedInProfile,
          priorWorkExperienceInFinance: values.priorWorkExperienceInFinance,
          totalWorkExperience: values.totalWorkExperience,
          workingProfessional: values.workingProfessional,
          email: userDetails.email,
          currentCompany: values.currentCompany,
          currentDesignation: values.currentDesignation
        }
      );




      let twoModules = items.filter(data => data.category === 'module');
      let batch = items.filter(data => data.category === 'batch');
      let skillUpChallenge = items.filter(data => data.id === 'skillUpChallenge');
      let hedgeAccounting = items.filter(data => data.id === 'hedgeAccouting');
      let executiveBatch1 = items.filter(data => data.id === 'executiveBatch1');
      let executiveModule = items.filter(data => data.category === 'executive-module');
      let comboProduct1 = items.filter(data => data.id === 'comboProduct1');
      let scholarHybridETraining = items.filter(data => data.category === 'scholarHybridETraining');
      let caftaCaseChampionship = items.filter(data => data.category === 'caftaCaseChampionship');
      let caftaScholar = items.filter(data => data.category === 'caftaScholar');
      let financeAndTreasuryBootcamp = items.filter(data => data.id === 'financeAndTreasuryBootcamp');
      let caftaSprintSeriesBatch1 = items.filter(data => data.id === 'caftaSprintSeriesBatch1');
      let caftaSprintSeriesBatch2 = items.filter(data => data.id === 'caftaSprintSeriesBatch2');
      // console.log(items);

      let transact = await createTransaction({
        ...values,
        email: userDetails.email,
        phone: userDetails.phone_number,
        twoModules: twoModules,
        batch: batch,
        skillUpChallenge: skillUpChallenge,
        hedgeAccounting: hedgeAccounting,
        executiveBatch1: executiveBatch1,
        executiveModule: executiveModule,
        comboProduct1: comboProduct1,
        scholarHybridETraining: scholarHybridETraining,
        caftaCaseChampionship: caftaCaseChampionship,
        caftaScholar: caftaScholar,
        financeAndTreasuryBootcamp: financeAndTreasuryBootcamp,
        price: {
          cost: totalCart,
          totalDiscount: couponDiscount,
          gst: (totalCart - couponDiscount) * 0.18,
          totalCost: parseFloat((totalCart - couponDiscount) * 1.18).toFixed(2),
          couponCode
        },
        caftaSprintSeriesBatch1: caftaSprintSeriesBatch1,
        caftaSprintSeriesBatch2: caftaSprintSeriesBatch2,
      });

      // window.open(transact);
      // console.log(transact);

      // Need to be un-comment
      transact = transact.replaceAll(/\\/g, '');
      // console.log(transact);
      var div = document.createElement('div');
      div.innerHTML = transact.trim();
      document.body.appendChild(div);

      // console.log(div.getElementsByTagName('form'));
      div.getElementsByTagName('form')[0].submit();

      form.resetFields();

      setIsModalVisible(false);
      showNotification('Order Placed!!', 'Your Order placed successfully!!', 'success');
      ClearCart();
      setTransactionLoading(false);
    } catch (e) {
      // console.log(e);
      showNotification('Error!!', e.message, 'danger');

    }
  };
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (items[0]?.category === 'caftaCaseChampionship' && items[0]?.teamSize > 1 && steps.length < 1) {
      setSteps([{ title: 'Team Details', content: getForm(form, 'form1', true) }])
    } else if (steps.length < 1) {
      setSteps([{ title: 'Form 1', content: getForm(form, 'form1', false) }])
    }
  }, [items])

  const getForm = (empForm, name, secondPersonDetails) => {
    return <Form
      form={empForm}
      name={name}
      initialValues={{
        prefix: '+91',
      }}
      scrollToFirstError
      className=""
    >
      <fieldset>
        <legend>Logged In User Profile Details:</legend>

        {items[0]?.category === 'caftaCaseChampionship' && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="teamName"
                label="Team Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Team Name!'
                  },
                ]}
              >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
                name="teamSize"
                label="Team Size"
              >
              <Input defaultValue={items[0].teamSize} disabled/>
            </Form.Item>
        </Col>

          </Row>
        ) }

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your First Name!'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="institution" label="Institution/College Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Institution Name!'
                },
              ]}
            >
              <Input />
              {/* <Select
                    allowClear
                  >
                    <Option value="Institute 1">Institute 1</Option>
                    <Option value="Institute 2">Institute 2</Option>
                    <Option value="Institute 3">Institute 3</Option>
                  </Select> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="gradYear" label="Select Graduation Year"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Graduation Year!'
                },
              ]}
            >
              <Select
                allowClear
              >
                <Option value="2028">2028</Option>
                <Option value="2027">2027</Option>
                <Option value="2026">2026</Option>
                <Option value="2025">2025</Option>
                <Option value="2024">2024</Option>
                <Option value="2023">2023</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="latestDegree"
              label="Latest Degree"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Latest Degree!'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              name="priorWorkExperienceInFinance"
              label="Prior work experience in finance/treasury (In Years)"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Prior work experience!'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item name="hearFrom" label="Select -- Where did you hear about us from?"
              rules={[
                {
                  required: true,
                  message: 'Please enter Where did you hear about us from?'
                },
              ]}
            >
              <Select
                allowClear
              >
                <Option value="LinkedIn">LinkedIn</Option>
                <Option value="Email">Email</Option>
                <Option value="Company Source">Company Source</Option>
                <Option value="Facebook">Facebook</Option>
                <Option value="Friend">Friend</Option>
                <Option value="From EY Employee">From EY Employee</Option>
                <Option value="Instagram">Instagram</Option>
                <Option value="Whatsapp Message/Broadcast">Whatsapp Message/Broadcast</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="totalWorkExperience"
              label="Total work experience (In Years)"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Total work experience!'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="currentCompany"
              label="Current Company"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Current Company!'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="currentDesignation"
              label="Current Designation"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Current Designation!'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="hearFrom" label="Select -- Where did you hear about us from?"
              rules={[
                {
                  required: true,
                  message: 'Please enter Where did you hear about us from?'
                },
              ]}
            >
              <Select
                allowClear
              >
                <Option value="LinkedIn">LinkedIn</Option>
                <Option value="Email">Email</Option>
                <Option value="Company Source">Company Source</Option>
                <Option value="Facebook">Facebook</Option>
                <Option value="Friend">Friend</Option>
                <Option value="From EY Employee">From EY Employee</Option>
                <Option value="Instagram">Instagram</Option>
                <Option value="Whatsapp Message/Broadcast">Whatsapp Message/Broadcast</Option>
              </Select>
            </Form.Item>
          </Col>
          
        </Row> */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="linkedInProfile"
              label="Linkedin Profile "
              rules={[
                {
                  required: true,
                  message: 'Please enter your Linkedin Profile!'
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row >
          <Col span={24}>
            <Form.Item
              name="workingProfessional"
              valuePropName="checked"
            >
              <Checkbox>
                Are you a working professional ?
              </Checkbox>
            </Form.Item>
          </Col>

        </Row>
      </fieldset>

      {secondPersonDetails ? (
        <fieldset>
          <legend>Second User Profile Details:</legend>
          <>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="secondUser_firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user First Name!'
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondUser_lastName"
                  label="Last Name"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="secondUser_institution" label="Institution/College Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Institution Name!'
                    },
                  ]}
                >
                  <Input />
                  {/* <Select
                      allowClear
                    >
                      <Option value="Institute 1">Institute 1</Option>
                      <Option value="Institute 2">Institute 2</Option>
                      <Option value="Institute 3">Institute 3</Option>
                    </Select> */}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="secondUser_gradYear" label="Select Graduation Year"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Graduation Year!'
                    },
                  ]}
                >
                  <Select
                    allowClear
                  >
                    <Option value="2028">2028</Option>
                    <Option value="2027">2027</Option>
                    <Option value="2026">2026</Option>
                    <Option value="2025">2025</Option>
                    <Option value="2024">2024</Option>
                    <Option value="2023">2023</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="secondUser_latestDegree"
                  label="Latest Degree"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Latest Degree!'
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="secondUser_hearFrom" label="Select -- Where did you hear about us from?"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Where did you hear about us from?'
                    },
                  ]}
                >
                  <Select
                    allowClear
                  >
                    <Option value="LinkedIn">LinkedIn</Option>
                    <Option value="Email">Email</Option>
                    <Option value="Company Source">Company Source</Option>
                    <Option value="Facebook">Facebook</Option>
                    <Option value="Friend">Friend</Option>
                    <Option value="From EY Employee">From EY Employee</Option>
                    <Option value="Instagram">Instagram</Option>
                    <Option value="Whatsapp Message/Broadcast">Whatsapp Message/Broadcast</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  name="secondUser_priorWorkExperienceInFinance"
                  label="Prior work experience in finance/treasury (In Years)"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Prior work experience!'
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col> */}
            </Row>
            {/* <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="secondUser_totalWorkExperience"
                  label="Total work experience (In Years)"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Total work experience!'
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondUser_currentCompany"
                  label="Current Company"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Current Company!'
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondUser_currentDesignation"
                  label="Current Designation"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Current Designation!'
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="secondUser_hearFrom" label="Select -- Where did you hear about us from?"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Where did you hear about us from?'
                    },
                  ]}
                >
                  <Select
                    allowClear
                  >
                    <Option value="LinkedIn">LinkedIn</Option>
                    <Option value="Email">Email</Option>
                    <Option value="Company Source">Company Source</Option>
                    <Option value="Facebook">Facebook</Option>
                    <Option value="Friend">Friend</Option>
                    <Option value="From EY Employee">From EY Employee</Option>
                    <Option value="Instagram">Instagram</Option>
                    <Option value="Whatsapp Message/Broadcast">Whatsapp Message/Broadcast</Option>
                  </Select>
                </Form.Item>
              </Col>
              
            </Row> */}
            
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="secondUser_linkedInProfile"
                  label="Linkedin Profile "
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Linkedin Profile!'
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondUser_email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Email!'
                    },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              
              <Col span={12}>
                <Form.Item
                  name="secondUser_phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Second user Phone Number!'
                    },
                  ]}
                >
                  <Input type="tel" />
                </Form.Item>
              </Col>
            </Row>
            <Row >
              <Col span={24}>
                <Form.Item
                  name="secondUser_workingProfessional"
                  valuePropName="checked"
                >
                  <Checkbox>
                    Are you a working professional ?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        </fieldset>
      ) : ""}


      <Row>
        <Col span={24}>
          <Form.Item
            name="term&condition"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Please read the terms & conditions')),
              },
            ]}
          >
            <Checkbox>
              I have read and agreed to the <Button type="link"  onClick={() => setShowTermsAndCondition(true)} >Terms & Conditions</Button>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  }

  return (
    <Row className="pt-82 bg-grey2 pb-40">
      <Col span={22} offset={1} className="cart-min-height">
        <Row>
          <Col span={24} className="mt-40 cost-table " >
            <Table dataSource={items} columns={columns} pagination={{ position: ['none', 'none'] }} />
          </Col>
          {
            items.length ? (
              <Col span={24} >
                <Row className="cart-total-row">
                  <Col span={14}>
                    Cart Total
                  </Col>
                  <Col span={10} className="pl-40">
                    INR {totalCart}
                  </Col>

                </Row>
                {(items[0].category !== 'module') && <Row className="cart-total-row">
                  <Col span={14}>
                    Coupon Code
                  </Col>
                  <Col span={8} className="pl-40">
                    <Row>
                      <Col span={24}>
                        <Search
                          ref={couponRef}
                          placeholder="Enter Coupon Code"
                          enterButton="Apply Coupon"
                          size="medium"
                          onSearch={applyCoupon}
                        />
                      </Col>
                      {couponCode && <Col span={24} className="mt-20">
                        <Item>
                          <Checkbox
                            checked="true"
                          >
                            <span>
                              Promo Code: <span className="fw-600">{couponCode} </span> Applied
                            </span>
                            <Button size="small" onClick={removeCoupon} type="primary" danger className="ml-20">Remove</Button>
                          </Checkbox>
                        </Item>
                      </Col>}
                    </Row>

                  </Col>
                </Row>}
                {(couponDiscount !== 0) && <Row className="cart-total-row">
                  <Col span={14}>
                    Discount
                  </Col>
                  <Col span={8} className="pl-40">
                    INR {couponDiscount}
                  </Col>
                </Row>}

                {(couponDiscount !== 0) && <Row className="cart-total-row">
                  <Col span={14}>
                    Cart Total After Discount
                  </Col>
                  <Col span={8} className="pl-40">
                    INR {totalCart - couponDiscount}
                  </Col>
                </Row>}

                <Row className="cart-total-row">
                  <Col span={14}>
                    <Text style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Final Cart Total (Including 18% GST)</Text>
                  </Col>
                  <Col span={8} className="pl-40">
                    <Text style={{ fontStyle: 'italic', fontWeight: 'bold' }}>INR {parseFloat((totalCart - couponDiscount) * 1.18).toFixed(2)}</Text>
                  </Col>
                </Row>

                <Row className="mt-40">
                  <Col span={14}>

                  </Col>
                  <Col span={10} className="pl-40">
                    <Button type="primary" onClick={payNow} loading={payNowLoading} className="">Pay Now</Button>
                  </Col>
                </Row>
              </Col>
            ) : (
              ""
            )
          }
        </Row>
      </Col>

      <Col span={24}>
        <Modal title="Please enter your details" width={1000} visible={isModalVisible} footer={null} okText="Pay Now" onOk={handleOk} confirmLoading={transactionLoading} onCancel={handleCancel}>

          <div>

            {/* {items[0]?.category === 'caftaCaseChampionship' && items[0]?.teamSize > 1 ? <Steps current={current} >
              {steps && steps.length > 0 && steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps> : ""} */}
            <div className="steps-content">{steps[current]?.content}</div>
            <div className="steps-action text-right">
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" onClick={() => handleOk()} loading={transactionLoading}>
                  Pay Now
                </Button>
              )}
              {current > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
            </div>
          </div>
        </Modal>
      </Col>

        {showTermsAndCondition && <Modal width={1000} bodyStyle={{height: '60%'}} title="CAFTA – Terms & Conditions" footer={null} visible={showTermsAndCondition} onCancel={() => setShowTermsAndCondition(false)}>
          <DocViewer style={{minHeight: '100%'}} pluginRenderers={DocViewerRenderers} documents={[{uri: 'https://eycafta.com/data/CAFTA_Terms%20and%20conditions.docx'}]} />
        </Modal>}
    </Row >
  );
}

const mapStateToProps = state => {
  return {
    items: [...state.caftaModules.carts]
  }
}

export default connect(mapStateToProps, { DeleteCart, ClearCart })(CartList)
