import React, { useContext } from "react";
import { Row, Col, Spin } from 'antd';
import "./about.css";
import { ConfigContext } from '../../context/context';

function About() {
  const {about} = {...useContext(ConfigContext)};
  
  return (
    <Row  id="aboutSection"  className="about-sec mt-40">
        {
            about && about.length ? (
            <Col span={24}>
              <Row  className="">
                  <Col className="section-title text-center mb-20" span={22} offset={1}>
                      <h3>{about[0].heading}</h3>
                  </Col>
                  <Col span={22} offset={1}>
                      {about[0].content && about[0].content.length > 0 && about[0].content.map((item, i) => <p className="block" key={`${item}-${i}`}>{item}</p>)}
                  </Col>
              </Row>
            </Col>
            )
            
            : (

              <Col span={24} className="text-center mt-40 mb-40">
                  <Spin tip="Loading..."></Spin>
              </Col>
            )
        }
    </Row>
  );
}

export default About;
