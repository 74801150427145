import React, { useState, useEffect } from "react";
import { Row,  Breadcrumb } from "antd";
import { HomeOutlined } from '@ant-design/icons';
import {
    NavLink,
    useParams,
    useLocation
  } from "react-router-dom";




function AppBreadCrumb() {
    const [breadcrumbTop, setbreadcrumbTop] = useState(82);
    const { title } = useParams();
    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const breadcrumbNameMap = {
        '/products/scholar': 'Scholar',
        // '/products/skill-up-challenge': 'Skill-Up-Challenge',
        '/products/executives': 'Executives',
        '/products/scholar/fullBatch': 'Full Batch',
        '/products/scholar/twoModule': 'Two Modules',
        '/products/executives/fullBatch': 'Full Batch',
        '/products/executives/twoModule': 'Two Modules',
        '/products/hedge-accounting': 'Hedge Accounting',
        "/products/finance-and-treasury-bootcamp": "Finance & Treasury Bootcamp",
        "/products/cafta-sprint-series": "CAFTA Sprint Series",
      };

    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        
        return (
            breadcrumbNameMap[url] && <Breadcrumb.Item key={url}>
            <NavLink to={url}>{breadcrumbNameMap[url]}</NavLink>
        </Breadcrumb.Item>
        );
    });

    const breadcrumbItems = [
        <Breadcrumb.Item key="/home">
            <NavLink to="/home">
                <HomeOutlined className="mr-5"/>Home
            </NavLink>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="/products">Products</Breadcrumb.Item>
    ].concat(extraBreadcrumbItems);
    
    if (title) {
        breadcrumbItems.push(
            <Breadcrumb.Item  key={title}>{title}</Breadcrumb.Item>
        )
    }
    
    const handleScroll = () => {
        if (window.scrollY === 0) {
            setbreadcrumbTop(82);
         }
         else {
            setbreadcrumbTop(75);
         }
     }
     

    useEffect(() => {
         window.addEventListener('scroll', handleScroll);
    }, [breadcrumbTop]);

    return (
        <Row className="app-breadcrumb" style={{top: breadcrumbTop, transition: 'top 0.1s ease-out'}}>
            <Breadcrumb>
                {breadcrumbItems}
                {/* <Breadcrumb.Item>
                    <NavLink to="/home">
                        <HomeOutlined className="mr-5"/>Home
                    </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Products</Breadcrumb.Item>
                {type && (
                    <Breadcrumb.Item>
                        <NavLink to={'/products/' + type + '/batches'}>{type}</NavLink>
                     </Breadcrumb.Item>
                )}
                {batchName && (
                    <Breadcrumb.Item>
                            <NavLink to={'/products/' + type + '/' + batchName}>{batchName}</NavLink>
                    </Breadcrumb.Item>
                )}
                {id && (
                    <Breadcrumb.Item>
                        <NavLink to={'/products/' + type + '/' + id + '/' + title}>{title}</NavLink>
                    </Breadcrumb.Item>
                )} */}
                
            </Breadcrumb>
        </Row>
    );
}

export default AppBreadCrumb;