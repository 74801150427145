import React from "react";
import Slider from "./Slider";
import useSlider from "./hooks/useSlider";
import ContentBox from "./ContentBox";

import {LeftOutlined, RightOutlined} from '@ant-design/icons';

import "./testimonials.css";
import { Button } from "antd";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Testimonials = ({ usersComments }) => {
  const { currentSlide, nextSlide, prevSlide, setSlide } = useSlider(
    usersComments
  );

  const ButtonControl = ({ go, icon }) => (
    <Button shape="round" onClick={go} icon={icon}>
      {/* <FontAwesomeIcon className="testimonials__arrow-svg" icon={faShape} /> */}
    </Button>
  );

  return (
    <div style={{margin: '0 auto'}}>
      <div className="testimonials">
        <div className="testimonials__slider-area">
          <div className="testimonials__button-container">
            <ButtonControl icon={<LeftOutlined />} go={prevSlide} />
          </div>
          <Slider {...{ usersComments, currentSlide, setSlide }} />
          <div className="testimonials__button-container">
            <ButtonControl icon={<RightOutlined />} go={nextSlide} />
          </div>
        </div>
        <div>
          {/* <Slider {...{ usersComments, currentSlide, setSlide }} /> */}
          <ContentBox {...usersComments[currentSlide]} />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
