import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Spin,
  Table,
  Button,
  Popconfirm,
  Space,
  Tooltip,
} from "antd";
import "./styles.css";
import AppBreadCrumb from "../header/breadcrumb";
import { AddCart } from "../../actions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";

const isRegistrationOpen = (date) => {
  if (!date) {
    return true;
  }

  return moment().isSameOrAfter(moment(date, "DD/MM/YYYY"));
};

const camelCaseToNormal = (camelString) => {
  return (
    camelString
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
};

const CaftaSprintSeries = ({ certificateData, AddCart }) => {
  const { Meta } = Card;
  const [addItem, setAddItem] = useState({});
  const history = useHistory();

  const navTo = (link) => {
    history.push(link);
  };

  useEffect(() => {
    const temp = {
      key: certificateData.key,
      id: certificateData.id,
      title: certificateData.title,
      category: certificateData.category,
      price: certificateData.price,
      currency: certificateData.currency,
      img: certificateData.img,
    };
    setAddItem(temp);
  }, []);

  const addToCart = (item) => {
    const data = {
      key: item.key,
      id: item.id,
      title: item.title,
      category: item.category,
      price: moment().isAfter(moment(item.earlyBirdEndDate, 'DD/MM/YYYY').endOf('day'))      
          ? item.price
          : item.earlyBirdPrice,
      currency: "INR",
    };

    AddCart(data);
  };

  const downloadBrochure = (key) => {
    var a = document.createElement("a"); //Create <a>
    a.href = `/brochure/${key}.pdf`; //Image Base64 Goes here
    a.download = "brochure.pdf"; //File name Here
    a.click();
  };

  return (
    <Row className="pt-82 bg-grey2">
      <Col className="mb-40" span={24}>
        <AppBreadCrumb></AppBreadCrumb>
      </Col>
      <Col span={22} offset={1}>
        {certificateData && Object.keys(certificateData).length ? (
          <Row gutter={[24, 24]} className="skill-up-challenge-sec mt-40 mb-40">
            {certificateData.description.cards.map((item) => (
              <Col md={24} lg={item.col} className="description-sec">
                <Row className="description-sec-wrapper">
                  <Col span={24} className="sec-title mb-20">
                    <h3>{item.heading}</h3>
                  </Col>
                  <Col span={24} className="about-challenge mt-20">
                    {Object.keys(item).map((obj) => {
                      return obj === "img" ? (
                        <>
                          {/* <ul
                            style={{
                              display: "flex",
                              gap: "50px",
                              flexWrap: "wrap",
                            }}
                          >
                            {item.img.slice(0, 2).map((value, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={`https://ey-portal.s3.us-east-2.amazonaws.com/front-end/images/cafta-sprint-series/team/${value.img}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                />
                                <p
                                  style={{
                                    whiteSpace: "pre",
                                    textAlign: "center",
                                  }}
                                >
                                  {value.name}
                                </p>
                              </div>
                            ))}
                          </ul> */}
                          <ul
                            style={{
                              display: "flex",
                              gap: "50px",
                              flexWrap: "wrap",
                            }}
                          >
                            {item.img.slice(2).map((value, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={`https://ey-portal.s3.us-east-2.amazonaws.com/front-end/images/cafta-sprint-series/team/${value.img}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                />
                                <p>{value.name}</p>
                              </div>
                            ))}
                          </ul>
                        </>
                      ) : obj === "heading2" ? (
                        <h4>{item.heading2}</h4>
                      ) : obj !== "addToCart" &&
                        obj !== "table" &&
                        obj !== "col" &&
                        obj !== "heading" &&
                        obj !== "heading2" &&
                        obj !== "forMoreInformationContact" &&
                        obj !== "basicRegister" &&
                        obj !== "advanceRegister" &&
                        obj !== "downloadBrochure" ? (
                        <>
                          <p className="fw-600">{camelCaseToNormal(obj)}</p>
                          {Array.isArray(item[obj]) ? (
                            <ul>
                              {item[obj].map((list) =>
                                Array.isArray(list) ? (
                                  <ul>
                                    {list.map((subListItem) => (
                                      <li>{subListItem}</li>
                                    ))}
                                  </ul>
                                ) : (
                                  <li>{list}</li>
                                )
                              )}
                            </ul>
                          ) : (
                            <p>{item[obj]}</p>
                          )}
                        </>
                      ) : obj === "table" ? (
                        <div className="hedge-accounting-table">
                          <Table
                            key={JSON.stringify(item[obj].dataSource)}
                            bordered
                            dataSource={item[obj].dataSource}
                            columns={item[obj].columns}
                            pagination={{
                              position: ["none", "none"],
                              pageSize: 11,
                            }}
                          />
                        </div>
                      ) : obj === "addToCart" ? (
                        <div className="">
                          <div className="hedge-accounting-btn text-center mb-10">
                            <Button onClick={() => AddCart(addItem)}>
                              Add To Cart
                            </Button>
                          </div>
                        </div>
                      ) : obj === "forMoreInformationContact" ? (
                        <div>
                          <br></br>
                          <p className="fw-600">{camelCaseToNormal(obj)}</p>
                          <b>{item[obj][0][0]}</b>
                          <br></br>
                          <>
                            Email :{" "}
                            <a href={`mailTo:cafta@in.ey.com`}>
                              {item[obj][0][1]}
                            </a>
                          </>
                          <br></br>
                          <br></br>
                          <p>
                            Or write us at{" "}
                            <a href="mailTo:cafta@in.ey.com">cafta@in.ey.com</a>
                          </p>
                          {/* <>Mobile : <a href="tel:+917303943512">{item[obj][2]}</a></> */}
                        </div>
                      ) : obj === "advanceRegister" ||
                        obj === "basicRegister" ? (
                        <>
                          <Row
                            gutter={[24, 24]}
                            className="live-training-list-sec"
                            style={{ marginTop: 10 }}
                          >
                            {item[obj].map((item, index) => {
                              return (
                                <Col
                                  key={`${item.title}-${index}`}
                                  m={{ span: 8 }}
                                >
                                  <Card
                                    className="stream-card"
                                    style={{ width: 400 }}
                                    actions={[
                                      <Tooltip
                                        title={
                                          !isRegistrationOpen(
                                            item.registrationStartDate
                                          )
                                            ? item.disabledRegistrationMessage
                                            : undefined
                                        }
                                      >
                                        <Button
                                          style={{
                                            background: "#2e2e38c7",
                                            borderColor: "#2e2e38",
                                            color: "rgb(228 222 222 / 85%)",
                                          }}
                                          onClick={() => {
                                            addToCart(item);
                                          }}
                                        >
                                          Add to cart
                                        </Button>
                                      </Tooltip>,
                                    ]}
                                    title={item.title}
                                  >
                                    <p
                                      style={{
                                        textAlign: "justify",
                                        height: "175px",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {item.description}
                                    </p>
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </>
                      ) : obj === "downloadBrochure" ? (
                        <div>
                          <div className="hedge-accounting-btn text-center mb-10">
                            <Button
                              onClick={() =>
                                downloadBrochure(certificateData.key)
                              }
                            >
                              Download Brochure
                            </Button>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <Col span={24} className="text-center mt-40 mb-40">
              <Spin tip="Loading..."></Spin>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
// export default HedgeAccounting;
export default connect(null, { AddCart })(CaftaSprintSeries);
