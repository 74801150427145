import React, {  } from "react";
import { Row, Col, Card, Spin } from "antd";
import {
  NavLink} from "react-router-dom";
import "./scholar.css";
import AppBreadCrumb from '../header/breadcrumb';


function Scholar({ scholarInfo }) {
  const { Meta } = Card;



  return (
    <Row className="pt-82 bg-grey2">
      <Col className="mb-40" span={24}>
        <AppBreadCrumb></AppBreadCrumb>
      </Col>
      <Col span={22} offset={1}>
        {scholarInfo && Object.keys(scholarInfo).length ? (
          <Row className="scholar-sec mt-40 mb-40">
            <Col span={24} className="scholar-sec-container">
              <Row>
                <Col span={24} className="scholar-title mt-5 pr-15 pl-15 mb-20">
                  <h3 >CAFTA Scholar</h3>
                </Col>
                <Col span={24} className="scholar-description pr-15 pl-15 mt-20">
                  <p>{scholarInfo.description.p1}</p>
                  <p>{scholarInfo.description.p2}</p>
                </Col>
              </Row>
            </Col>

            <Col span={24} className="mt-20 live-training-wrapper">
              <Row>
                <Col span={24} className="live-training-title pr-15 pl-15 mt-5 mb-20">
                  <h3 >Hybrid E-Trainings</h3>
                </Col>
                <Col span={24} className="mt-20 mb-20">
                  <Row gutter={[16, 16]} className="live-training-list-sec ">
                    {/* {scholarInfo.hybridETrainingList && scholarInfo.hybridETrainingList.length ? ( */}
                    {scholarInfo.hybridETrainingList.map((item, i) => (
                      <Col key={`${item.title}-${i}`} m={{ span: 4 }}>
                        <NavLink to={item.url}>
                          <Card
                            className="front-card"
                            style={{ width: '250px' }}
                            hoverable
                            cover={
                              <img alt="Course" src={item.img} />
                            }>
                            <Meta title={item.title === 'Scholars’ Program' ?  "CAFTA" : "Certificate In"} description={item.title} />
                          </Card>
                        </NavLink>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24} className="text-center mt-40 mb-40">
              <Spin tip="Loading..."></Spin>
            </Col>
          </Row>

        )}
      </Col>
    </Row>

  );
}


export default Scholar;
