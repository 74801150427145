import React, { useRef } from "react";
import { Form, Input, Select, Row, Col, Checkbox, Button, Popover } from 'antd';
import "./sign-in.css";
// import { saveSession, userGet, userLogin, userRegister } from "../../utils";
import { Store } from 'react-notifications-component';
import { Auth } from "aws-amplify";
import { useAppContext } from "../../lib/contextLib";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
const { Option } = Select;
const passwordValidator = require('password-validator');

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const RegistrationForm = ({ handleToggle }) => {
    const title = 'Password Policy';
    const passwordPolicyContent = (
        <React.Fragment>
            <h4>Your password should contain: </h4>
            <ul>
                <li>Minimum length of 8 characters</li>
                <li>Numerical characters (0-9)</li>
                <li>Special characters</li>
                <li>Uppercase letter</li>
                <li>Lowercase letter</li>
            </ul>
        </React.Fragment>
    );
    const schema = new passwordValidator();
    schema
        .is()
        .min(8)
        .has()
        .uppercase()
        .has()
        .lowercase()
        .has()
        .digits()
        .has()
        .symbols();
    const [form] = Form.useForm();
    const { isAuthenticated, userHasAuthenticated } = useAppContext();

    const { getFieldDecorator } = form;



    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        let user;

        try {
            await Auth.signUp({
                username: values.email, password: values.password, attributes: {
                    phone_number: `${values.prefix}${values.phone}`
                }
            });
            form.resetFields();
            Store.addNotification({
                title: "Sucess!!",
                message: "Your account has been created. We have sent an email to your registered email Id. Please Verify to proceed with Login!!'",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__flipInx"],
                animationOut: ["animate__animated", "animate__flipOutx"],
                dismiss: {
                    duration: 5000,
                    onScreen: true,
                    showIcon: true
                }
            });

            setLoading(false);
            handleToggle();
        } catch (e) {
            Store.addNotification({
                title: "Error!",
                message: e.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__flipInx"],
                animationOut: ["animate__animated", "animate__flipOutx"],
                dismiss: {
                    duration: 5000,
                    onScreen: true,
                    showIcon: true
                }
            });
            setLoading(false);
        }




    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="+91">+91</Option>
            </Select>
        </Form.Item>
    );

    const validateToNextPassword = (rule, value, callback) => {
        // const form = this.props.form;
        const validationRulesErrors = schema.validate(value, { list: true });

        // if (value && this.state.confirmDirty) {
        //   form.validateFields(['confirm'], { force: true });
        // }
        if (validationRulesErrors.length > 0) {
            callback(formatPasswordValidateError(validationRulesErrors));
        }
        callback();
    };

    const formatPasswordValidateError = (errors) => {

        for (const error of errors) {
            if (error === 'min') {
                return 'password length should be a at least 8 characters';
            } else if (error === 'lowercase') {
                return 'password should contain lowercase letters';
            } else if (error === 'uppercase') {
                return 'password should contain uppercase letters';
            } else if (error === 'digits') {
                return 'password should contain digits';
            } else if (error === 'symbols') {
                return 'password should contain symbols';
            }
        }

        /* for (let i = 0; i < errors.length; i++) {
            if (errors[i] === 'min') {
                return 'password length should be a at least 8 characters';
            } else if (errors[i] === 'lowercase') {
                return 'password should contain lowercase letters';
            } else if (errors[i] === 'uppercase') {
                return 'password should contain uppercase letters';
            } else if (errors[i] === 'digits') {
                return 'password should contain digits';
            } else if (errors[i] === 'symbols') {
                return 'password should contain symbols';
            }
        } */
    };



    return (
        <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
                prefix: '+91',
            }}
            scrollToFirstError
            className="signupForm"
        >
            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                    {
                        required: true,
                        message: 'Please input your phone number!',
                    },
                ]}
            >
                <Input
                    addonBefore={prefixSelector}
                    style={{
                        width: '100%',
                    }}
                />
            </Form.Item>

            <Popover placement="right" title={title} content={passwordPolicyContent} trigger="focus">

            <Form.Item
                name="password"
                label="Password"
                rules={[
                    // {
                    //     required: true,
                    //     message: 'Please input your password!',
                    // },
                    {
                        validator: validateToNextPassword
                    }
                    // ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //         if (!value || getFieldValue('password') === value) {
                    //             return Promise.resolve();
                    //         }

                    //         return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    //     },
                    // }),
                ]}
                hasFeedback>
                    <Input.Password />
            </Form.Item>
                </Popover>
            {/* <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                        validator: validateToNextPassword
                    },
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item> */}

            <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                    },
                ]}
                {...tailFormItemLayout}
            >
                <Checkbox>
                    I have read the <a href="">agreement</a>
                </Checkbox>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Register
                </Button>
            </Form.Item>
        </Form>
    );
};

const LoginForm = () => {

    const { userHasAuthenticated } = useAppContext();

    const [loginForm] = Form.useForm();

    const [loading, setLoading] = React.useState(false);

    const history = useHistory();


    const onFinish = async (values) => {
        setLoading(true);

        try {
            const user = await Auth.signIn(values.email, values.password);
            console.log(user);
            userHasAuthenticated(true);
            window.location.replace('/')

        } catch (e) {
            Store.addNotification({
                title: "Error!",
                message: e.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__flipInx"],
                animationOut: ["animate__animated", "animate__flipOutx"],
                dismiss: {
                    duration: 5000,
                    onScreen: true,
                    showIcon: true
                }
            });
            setLoading(false);
        }

    };

    return (
      <Form
        {...formItemLayout}
        form={loginForm}
        name="login"
        onFinish={onFinish}
        scrollToFirstError
        className="loginForm"
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <div
          style={{
            marginTop: "10px",
            textAlign: "right",
          }}
        >
          <NavLink to="/forgot-password">Forgot Password</NavLink >
        </div>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Log In
          </Button>
        </Form.Item>

      </Form>
    );
};

function SignIn() {
    const loginBlock = useRef(null);
    const loginContent = useRef(null);
    const bar = useRef(null);
    const signupBlock = useRef(null);
    const signupContent = useRef(null);
    const leftSide = useRef(null);
    const rightSide = useRef(null);
    const loginText = useRef(null);
    const signupText = useRef(null);

    const handleToggle = () => {
        if (loginBlock.current.style.marginLeft === "-853px") {
            loginBlock.current.style.marginLeft = "0px";
            bar.current.style.marginLeft = "calc(100% - 300px)";
            signupBlock.current.style.left = "100%";
            loginContent.current.style.marginLeft = "0px";
            rightSide.current.style.right = "0px";
            leftSide.current.style.left = "-400px";
            signupText.current.style.top = "50%";
            loginText.current.style.top = "-100%";

        } else {
            loginBlock.current.style.marginLeft = "-853px";
            bar.current.style.marginLeft = "0px";
            signupBlock.current.style.left = "300px";
            loginContent.current.style.marginLeft = "1000px";
            rightSide.current.style.right = "-400px";
            leftSide.current.style.left = "0px";
            signupText.current.style.top = "200%";
            loginText.current.style.top = "50%";
        }
        if (signupContent.current.style.marginLeft === "0px") {
            signupContent.current.style.marginLeft = "-800px";
        }
        else {
            signupContent.current.style.marginLeft = "0px";
        }
    }

    return (
        <Row className="sign-in-up pt-82 bg-grey2" >
            <Col span="18" offset={3} className="mt-40 signin-min-height mb-40">
                <div className="container-sec">
                    <div ref={leftSide} className="leftSide">
                        <div className="title">One of us?</div>
                        <div className="text">If you already have an account you can log in.</div>
                    </div>
                    <div ref={rightSide} className="rightSide">
                        <div className="title">New here?</div>
                        <div className="text">You can create your own account right now!</div>
                    </div>
                    <div ref={loginBlock} className="loginBlock">
                        <div ref={loginContent} className="loginContent">
                            < LoginForm />
                            {/* <form  className="loginForm">
                            <div className="wrapper">
                            <input type="text"  />
                            <label htmlFor="username">Username or email</label>
                            </div>
                            <div className="wrapper">
                            <input type="password"  />
                            <label htmlFor="password">Password</label>
                            </div>
                            <Button className="sign-in-btn">Log In</Button>
                        </form> */}
                        </div>
                    </div>
                    <div ref={bar} className="bar">
                        <div className="mask">
                            <div onClick={handleToggle} className="toggle">
                                <div className="dot">.</div>
                                <div ref={loginText} className="loginText">Log&nbsp;In</div>
                                <div ref={signupText} className="signupText">Sign&nbsp;Up</div>
                            </div>
                        </div>
                    </div>
                    <div ref={signupBlock} className="signupBlock">
                        <div ref={signupContent} className="signupContent">
                            <RegistrationForm handleToggle={handleToggle} />
                            {/* <form  className="signupForm">
                                <div className="wrapper">
                                    <input type="text"   required />
                                    <label htmlFor="name">Name</label>
                                    <i className="far fa-check-square"></i>
                                </div>
                                <div className="wrapper">
                                    <input type="text"   required />
                                    <label htmlFor="username">Username</label>
                                    <i className="far fa-check-square"></i>
                                </div>
                                <div className="wrapper">
                                    <input type="email"  required />
                                    <label htmlFor="email">Email</label>
                                    <i className="far fa-check-square"></i>
                                </div>
                                <div className="wrapper">      
                                    <input type="password"  required />
                                    <label htmlFor="password">Password</label>
                                    <i className="far fa-check-square"></i>
                                </div>
                                <Button className="sign-up-btn">Sign up</Button>
                            </form> */}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default SignIn;