import React, { useState } from "react";
import { Row, Col, Input, Form, Button } from "antd";
import "./styles.css";
import { sendEmail } from '../../utils/api';
import { showNotification } from "../../reducers/reducer";
const { TextArea } = Input;

function CollegeContactUs() {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        console.log(values);
        // TODO:
        onFinish(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

  };

  const onFinish = async (values) => {
    console.log(values);
    setLoading(true);
    await sendEmail(values);
    showNotification('Mail has been Sent!!', `Mail has been Sent Successfully!!`, 'success');
    setLoading(false);
    form.resetFields();

  }


  return (
    <Row className="pt-82" style={{ placeContent: "center" }}>

      <div style={{marginTop: 50}} >
      <h1 align="center" style={{fontSize: 35}}>Contact Us</h1>

        <Form form={form} name="webinar-contact-us" >
          <Row gutter={[10, 10]}>
            <Col span={8}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Name!',
                  },
                ]}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Phone Number!',
                  },
                ]}
              >
                <Input placeholder="Phone Number" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                    message: 'Please input your message!',
                  },
                ]}
              >
                <TextArea placeholder="Message" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Button loading={loading} style={{float: "right"}} onClick={() => handleOk()}>Submit</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Row>
  )
}

export default CollegeContactUs;