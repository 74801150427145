import React, { useContext } from "react";
import { Row, Col, Spin } from "antd";
import "./teams.css";
import { ConfigContext } from '../../context/context';
import Testimonials from "../testimonials/Testimonials";
import { YoutubeOutlined } from '@ant-design/icons';

function Testimonial() {
  const {teams} = {...useContext(ConfigContext)};

  const usersComments = [
    {
      url: <YoutubeOutlined style={{fontSize: '40px', color: '#FF0000'}} />,
      name: "Sahil Agarwal",
      status: "IIT Kharagpur",
      story: `React components implement a  
      method that takes input data and returns 
      what to display. This example uses an 
      XML-like syntax called JSX. Input data 
      that is passed into the component can be 
      accessed by  via this.props`,
      titleStory: "story ",
      afterwords: "afterwords",
      embedId: "pXJaB31NJsc"
    },
    {
        url: <YoutubeOutlined style={{fontSize: '40px', color: '#FF0000'}} />,
      name: "Kabir Grover",
      status: "IMI Delhi",
      story: `React components implement a  
      method that takes input data and returns 
      what to display. This example uses an 
      XML-like syntax called JSX. Input data 
      that is passed into the component can be 
      accessed by  via this.props`,
      titleStory: "title sory",
      afterwords: "afterwords",
      embedId: "lSUW9fWhzo8"
    },
    {
        url: <YoutubeOutlined style={{fontSize: '40px', color: '#FF0000'}} />,
      name: "Kevin Fredrick",
      status: "NIT Trichy",
      story: `React components implement a  
      method that takes input data and returns 
      what to display. This example uses an 
      XML-like syntax called JSX. Input data 
      that is passed into the component can be 
      accessed by  via this.props`,
      titleStory: "title",
      afterwords: "afterwords",
      embedId: "ThR8euqchGQ"
    },
    {
        url: <YoutubeOutlined style={{fontSize: '40px', color: '#FF0000'}} />,
      name: "Sourav Kumar",
      status: "IMI Delhi",
      story: `React components implement a  
        method that takes input data and returns 
        what to display. This example uses an 
        XML-like syntax called JSX. Input data 
        that is passed into the component can be 
        accessed by  via this.props`,
      titleStory: "story ",
      afterwords: "afterwords",
      embedId: "aGSA85zoCMI"
    },
    {
        url: <YoutubeOutlined style={{fontSize: '40px', color: '#FF0000'}} />,
      name: "Saurabh Rajput",
      status: "IBS Mumbai",
      story: `React components implement a  
      method that takes input data and returns 
      what to display. This example uses an 
      XML-like syntax called JSX. Input data 
      that is passed into the component can be 
      accessed by  via this.props`,
      titleStory: "title sory",
      afterwords: "afterwords",
      embedId: "PP_uYTWY5dQ"
    }
  ];

  return (
    <Row id="teamSection" className="team-sec mt-40">
        {teams && teams.length ? (
        <Col span={24} className="text-center">
            <Row className="section-title">
                <Col className="mb-20"  span={24}>
                <h3>Testimonials</h3>
                </Col>
            </Row>
            <Row className="mt-40">
                <Testimonials {...{ usersComments }} />;
                {/* {
                teams.map((d, i) => (
                    <Col span={6}  key={`${d.name}-${i}`} className="team">
                        <div className="thumbnail">
                        {" "}
                        <img src={process.env.PUBLIC_URL + d.img} alt="..." className="team-img" />
                        <div className="caption">
                            <h4>{d.name}</h4>
                            <p>{d.job}</p>
                        </div>
                        </div>
                    </Col>
                    ))
                } */}
            </Row>
        </Col>
        ) : (
          <Col span={24} className="text-center mt-40 mb-40">
              <Spin tip="Loading..."></Spin>
          </Col>
          )}
      </Row>
  );
}

export default Testimonial;
