import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin, Table, Button } from "antd";
import "./styles.css";
import AppBreadCrumb from '../header/breadcrumb';
import { AddCart } from '../../actions/action'
import { connect } from 'react-redux';
import { useHistory } from "react-router";


const camelCaseToNormal = (camelString) => {
    return camelString.replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, (str) => (str.toUpperCase()))
}

const ScholarCertificate = ({ certificateData, AddCart }) => {
    const { Meta } = Card;
    const [addItem, setAddItem] = useState({});
    const [comboItem] = useState({
        key: "comboProduct1",
        id: "comboProduct1",
        title: "Combo Product 1",
        category: "Hedge accounting + Executive CAFTA",
        price: 25000,
        currency: "INR",
        img: "http://study.com/cimages/course-image/praxis-ii-business-education-test_118084_large.jpg"
    })
    const history = useHistory();


    const navTo = (link) => {
        history.push(link);
      }

    useEffect(() => {
        console.log(certificateData);
        const temp = {
            key: certificateData.key,
            id: certificateData.id,
            title: certificateData.title,
            category: certificateData.category,
            price: certificateData.price,
            currency: certificateData.currency,
            img: certificateData.img
        }
        setAddItem(temp);
    }, []);

    const downloadBrochure = (key) => {
        var a = document.createElement("a"); //Create <a>
        a.href = `/brochure/${key}.pdf`; //Image Base64 Goes here
        a.download = "brochure.pdf"; //File name Here
        a.click();
    }



    return (
        <Row className="pt-82 bg-grey2">
            <Col className="mb-40" span={24}>
                <AppBreadCrumb></AppBreadCrumb>
            </Col>
            <Col span={22} offset={1}>
                {certificateData && Object.keys(certificateData).length ? (

                    <Row gutter={[24, 24]} className="skill-up-challenge-sec mt-40 mb-40">
                        {certificateData.description.cards.map(item => (
                            <Col md={24} lg={item.col} className="description-sec">
                                <Row className="description-sec-wrapper">
                                    <Col span={24} className="sec-title mb-20">
                                        <h3 >{item.heading}</h3>
                                    </Col>
                                    <Col span={24} className="about-challenge mt-20">
                                        {Object.keys(item).map(obj => {
                                            return ((obj !== "addToCart" && obj !== "table" && obj !== "col" && obj !== "heading" && obj !== "forMoreInformationContact" && obj !== "downloadBrochure") ? (
                                                <>
                                                    <p className="fw-600">{camelCaseToNormal(obj)}</p>
                                                    <ul>
                                                        {Array.isArray(item[obj]) ? item[obj].map(list => (
                                                            <li>{list}</li>
                                                        )) : (<p>{item[obj]}</p>)}
                                                    </ul>
                                                </>) : obj === "table" ?
                                                            (<div className="hedge-accounting-table"><Table bordered dataSource={item[obj].dataSource} columns={item[obj].columns} pagination={{ position: ['none', 'none'] }}/></div> )
                                                : obj === "addToCart" ? (
                                                <div className="">
                                                    <div className="hedge-accounting-btn text-center mb-10">
                                                        <Button onClick={() => AddCart(addItem)}>Add To Cart</Button>
                                                    </div>
                                                </div>
                                                ) : obj === "forMoreInformationContact" ? (
                                                    <div>
                                                        <br></br>
                                                        <p className="fw-600">{camelCaseToNormal(obj)}</p>
                                                        <b>{item[obj][0]}</b><br></br>
                                                        <>Email : <a href="mailTo:cafta@in.ey.com">{item[obj][1]}</a></><br></br>
                                                        <>Mobile : <a href="tel:+917303943512">{item[obj][2]}</a></>
                                                    </div>
                                                    ) : obj === "downloadBrochure" ? (
                                                        <div>
                                                            <div className="hedge-accounting-btn text-center mb-10">
                                                                <Button onClick={() => downloadBrochure(certificateData.key)}>Download Brochure</Button>
                                                            </div>
                                                            {(certificateData.key === 'caftaScholar' || certificateData.key === 'cashAndLiquidity' || certificateData.key === 'corporateFinance' || certificateData.key === 'financialRiskManagement' || certificateData.key === 'investmentRiskManagement' || certificateData.key === 'supplyChainAndTradeFinance' || certificateData.key === 'treasuryAnalytics') &&  <span>Access to the course will be provided to the student within 24 hours after registration.</span>}
                                                        </div>
                                                        ) : ""
                                            )
                                        })}

                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Row>
                        <Col span={24} className="text-center mt-40 mb-40">
                            <Spin tip="Loading..."></Spin>
                        </Col>
                    </Row>

                )}
            </Col>
        </Row>

    );
}
// export default HedgeAccounting;
export default connect(null, { AddCart })(ScholarCertificate)