import React, { useEffect, useState } from "react";
import { Col, Spin, Button, Table, Input, InputNumber, Popconfirm, Form, Tabs, Modal, Select } from 'antd';
import "./admin.css";
// import { Tabs, Form } from 'antd';
import { Sticky } from 'react-sticky';
import 'antd/dist/antd.css';
import { createCoupon, deleteCoupon, getAllCoupon } from "../../utils/api";
import {showNotification} from '../../reducers/reducer';
import Reporting from "./reporting";
import Transcation from "./transaction";
const { TabPane } = Tabs;
const { Option } = Select;
// import './index.css';
const originData = [];




function callback(key) {
  console.log(key);
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          // style={{
          //   margin: 0,
          // }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const getCouponData = async (setData) => {
  console.log('getCouponData');
  setData([]);
  const coupons = await getAllCoupon();

  coupons.message.filter(item => item['key'] = item.hk);
  setData(coupons.message);
}

const EditableTable = ({refreshData}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');

  const [confirmLoading, setConfirmLoading] = useState(false);

  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    console.log(refreshData);
    // getCouponData(setData);
    if (!refreshData) {
      getCouponData(setData);
    }
  }, [refreshData]);

  const edit = (record) => {
    form.setFieldsValue({
      hk: '',
      product: '',
      discountPercent: '',
      discountPrice: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const deleteItem = async (key) => {
    try {
      // const row = await form.validateFields();
      setConfirmLoading(true);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        await deleteCoupon(item);
        newData.splice(index, 1);
        setData(newData);
        setEditingKey('');
      } 
      showNotification('Coupon Delete!!', 'Coupon Deleted successfully!!', 'success');

      setConfirmLoading(false);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Coupon Name',
      dataIndex: 'hk',
      width: '25%',
      editable: true,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      width: '15%',
      editable: true,
    },
    {
      title: 'Discount Percent',
      dataIndex: 'couponPercent',
      width: '20%',
      editable: true,
    },
    {
      title: 'Discount Price',
      dataIndex: 'couponPrice',
      width: '20%',
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '10%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
            // style={{
            //   marginRight: 8,
            // }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a style={{ marginLeft: '10px' }}>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div>
            {/* <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link> */}
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteItem(record.key)} okButtonProps={{ loading: confirmLoading }}>
              <a style={{ marginLeft: '10px' }}>Delete</a>
            </Popconfirm>
          </div>

        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    (data && data.length > 0) ? (<Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
      // pagination={{
      //   onChange: cancel,
      // }}
      />
    </Form>) : (

      <Col span={24} className="text-center mt-40 mb-40">
        <Spin tip="Loading..."></Spin>
      </Col>
    )
  );
};

// for (let i = 0; i < 10; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


const submitCoupon = async (values, setModalProcessing, setModalVisible, form) => {
  await createCoupon(values);

  form.resetFields();

  showNotification('Coupon Created!!', 'Coupon Created successfully!!', 'success');
  setModalProcessing(false);
  setModalVisible(false);
  // getCouponData();
  
}

// const deleteCoupon = async (values, setModalProcessing, setModalVisible, form) => {
//   await createCoupon(values);

//   form.resetFields();
//   setModalProcessing(false);
//   setModalVisible(false);
// }

// export const showNotification = (title, message, type) => {
//   Store.addNotification({
//       title,
//       message,
//       type,
//       insert: "top",
//       container: "top-right",
//       animationIn: ["animate__animated", "animate__flipInx"],
//       animationOut: ["animate__animated", "animate__flipOutx"],
//       dismiss: {
//           duration: 5000,
//           onScreen: true,
//           showIcon: true
//       }
//   });
// }


const CouponModal = ({ form, modalVisible, setModalVisible, modalProcessing, setModalProcessing }) => {

  const handleModalOk = () => {
    setModalProcessing(true);
    form
      .validateFields()
      .then(values => {
        // onFinish(values);
        console.log(values);
        
        submitCoupon(values, setModalProcessing, setModalVisible, form )
       
        // setModalProcessing(false);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setModalProcessing(false);
      });

  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (<Modal title="Create New Discount Coupon" width={350} visible={modalVisible} okText="Create" onOk={handleModalOk} confirmLoading={modalProcessing} onCancel={handleCancel}>
    <Form
      form={form}
      name="coupon"
      scrollToFirstError
    >
      <Form.Item
        name="hk"
        label="Coupon Name"
        rules={[
          {
            required: true,
            message: 'Please enter Coupon Name!'
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="product"
        label="Product"
        rules={[
          {
            required: true,
            message: 'Please Select Product!'
          },
        ]}
      >
        <Select
          allowClear
        >
          <Option value="caftaSprintSeries">Cafta Sprint Series</Option>
          <Option value="scholar-batch">Full Cafta(Scholar)</Option>
          <Option value="skillUpChallenge">Skill Up Challenge</Option>
          <Option value="caftaExecutiveBatch">Cafta Executive Batch</Option>
          <Option value="hedgeAccounting">Hedge Accounting</Option>
          <Option value="comboProduct1">Combo Product (CTHA + CAFTA)</Option>
          <Option value="scholarHybridETraining">Scholar Hybrid E Training</Option>
          <Option value="caftaCaseChampionship">Cafta Case Championship</Option>
          <Option value="financeAndTreasuryBootcamp">Finance & Treasury Bootcamp</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="couponPercent"
        label="Coupon Discount"
        rules={[
          {
            required: true,
            message: 'Please enter Discount Percent!'
          },
        ]}
      >
        <Input step=".01" type="number" suffix="%"/>
      </Form.Item>

      <Form.Item
        name="couponPrice"
        label="Coupon Price"
        rules={[
          {
            required: true,
            message: 'Please enter Discount Price!'
          },
        ]}
      >
        <Input step=".01" type="number" />
      </Form.Item>

    </Form>
  </Modal>)
};

function Admin() {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalProcessing, setModalProcessing] = React.useState(false);




  return (
    <div id="adminSection" className="admin-sec">
      {/* <StickyContainer> */}
      <Tabs defaultActiveKey="3" onChange={callback}>
        <TabPane tab="Coupon Code Section" key="1">
          <div>
            <Button style={{ float: 'right' }} onClick={() => setModalVisible(true)}>Create</Button>
          </div>
          <EditableTable refreshData={modalProcessing} />
          <CouponModal form={form} modalVisible={modalVisible} setModalVisible={setModalVisible} modalProcessing={modalProcessing} setModalProcessing={setModalProcessing} />
        </TabPane>
        <TabPane tab="Profiles" key="2">
          <Reporting />
        </TabPane>
        <TabPane tab="Transactions" key="3">
          <Transcation />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Admin;
