import { message, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { getTransaction } from "../../utils/api";



const Transcation = () => {

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);


  const productChange = async (val) => {
    console.log(val);


    setLoading(true);
    const txnData = await getTransaction({ product: val });

    setData(txnData.message);
    setLoading(false);
  }



  return (
    <div className="text-center">
      <Select loading={loading} placeholder="Select Product" align="right" onChange={productChange} >
        <Option value="Cafta Case Championship">Cafta Case Championship</Option>
        <Option value="Scholar Hybrid E Training">Scholar Hybrid E Training</Option>
        <Option value="CAFTA Scholars’">CAFTA Scholars’</Option>
        <Option value="Finance And Treasury Bootcamp">Finance And Treasury Bootcamp</Option>
        <Option value="Cafta Sprint Series - Batch 1">Cafta Sprint Series - Batch 1</Option>
        <Option value="Cafta Sprint Series - Batch 2">Cafta Sprint Series - Batch 2</Option>
      </Select>

      <CSVLink
      style={{marginLeft: 5}}
        filename={"txn_data.csv"}
        data={data}
        className="btn btn-primary"
        onClick={() => {
          message.success("The file is downloading")
        }}
      >

        Export to CSV
      </CSVLink>


    </div>
  )
}

export default Transcation;