import { Table, Input, Button, Space, message } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import { getAllProfile } from "../../utils/api";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";

const Reporting = () => {

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      label: 'First Name',
    },
    {
      title: 'Last Name',
      label: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      label: 'Email',
      dataIndex: 'hk',
      key: 'hk',
    },
    {
      title: 'Institution',
      label: 'Institution',
      dataIndex: 'institution',
      key: 'institution',
    },
    {
      title: 'latest Degree',
      label: 'latest Degree',
      dataIndex: 'latestDegree',
      key: 'latestDegree',
    },
    {
      title: 'Graduation Year',
      label: 'Graduation Year',
      dataIndex: 'gradYear',
      key: 'gradYear',
    },
    {
      title: 'Total Work Experience',
      label: 'Total Work Experience',
      dataIndex: 'totalWorkExperience',
      key: 'totalWorkExperience',
    },
    {
      title: 'Prior Work Experience in Finance',
      label: 'Prior Work Experience in Finance',
      dataIndex: 'priorWorkExperienceInFinance',
      key: 'priorWorkExperienceInFinance',
    },
    {
      title: 'Hear From',
      label: 'Hear From',
      dataIndex: 'hearFrom',
      key: 'hearFrom',
    },
    {
      title: 'LinkedIn Profile',
      label: 'LinkedIn Profile',
      dataIndex: 'linkedInProfile',
      key: 'linkedInProfile',
    }

  ];

  const [allProfiles, setAllProfile] = useState([]);

  const [loading, setLoading] = useState(false);


  const getAllProfiles = async () => {
    setLoading(true);
    setAllProfile([]);
    const profiles = await getAllProfile();

    setLoading(false);

    // if (Array.isArray(profiles.message)) {
    setAllProfile(profiles.message);
    // }
  }

  useEffect(() => {
    getAllProfiles();
  }, [])

  


  return (<div>
    {/* <Select placeholder="Select Product" align="right" onChange={productChange} >
            <Option value="Cafta Case Championship">Cafta Case Championship</Option>
        </Select> */}
        <CSVLink
              filename={"profile_data.csv"}
              data={allProfiles}
              className="btn btn-primary"
              onClick={()=>{
                message.success("The file is downloading")
              }}
              headers={columns}
              style={{float: "right"}}
            >
              
              Export to CSV
            </CSVLink>
    <Table loading={loading} dataSource={allProfiles} columns={columns} />;
  </div>)
}

export default Reporting;