import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin, Table, Button } from "antd";
import "./index.css";
import AppBreadCrumb from "../header/breadcrumb";
import { AddCart } from "../../actions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";

const camelCaseToNormal = (camelString) => {
  return (
    camelString
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, (str) => str.toUpperCase())
  );
};

const FinanceAndTreasuryBootcamp = ({ data, AddCart }) => {
  const { Meta } = Card;
  const history = useHistory();

  const navTo = (link) => {
    history.push(link);
  };

  const downloadBrochure = (key) => {
    var a = document.createElement("a"); //Create <a>
    a.href = `/brochure/${key}.pdf`; //Image Base64 Goes here
    a.download = "brochure.pdf"; //File name Here
    a.click();
  };

  return (
    <Row className="pt-82 bg-grey2">
      <Col className="mb-40" span={24}>
        <AppBreadCrumb></AppBreadCrumb>
      </Col>
      <Col span={22} offset={1}>
        {data && Object.keys(data).length ? (
          <Row gutter={[24, 24]} className="skill-up-challenge-sec mt-40 mb-40">
            {data.description.cards.map((item) => (
              <Col md={24} lg={item.col} className="description-sec">
                <Row className="description-sec-wrapper">
                  <Col span={24} className="sec-title mb-20">
                    <h3>{item.heading}</h3>
                  </Col>
                  <Col span={24} className="about-challenge mt-20">
                    {Object.keys(item).map((obj) => {
                      return obj === "img" ? (
                        <ul
                          style={{
                            display: "flex",
                            gap: "50px",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.img.map((value, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`https://ey-portal.s3.us-east-2.amazonaws.com/front-end/images/finance-and-treasury-bootcamp/team/${value.img}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                              />
                              <p>{value.name}</p>
                            </div>
                          ))}
                        </ul>
                      ) : obj !== "addToCart" &&
                        obj !== "table" &&
                        obj !== "col" &&
                        obj !== "heading" &&
                        obj !== "downloadBrochure" ? (
                        <>
                          <p className="fw-600">{camelCaseToNormal(obj)}</p>
                          {typeof item[obj] === "string" ? (
                            <p>{item[obj]}</p>
                          ) : (
                            <ul>
                              {item[obj].map((list) => (
                                <li>{list}</li>
                              ))}
                            </ul>
                          )}
                        </>
                      ) : obj === "table" ? (
                        <div className="hedge-accounting-table">
                          <Table
                            bordered
                            dataSource={item[obj].dataSource}
                            columns={item[obj].columns}
                            pagination={{ position: ["none", "none"] }}
                          />
                        </div>
                      ) : obj === "addToCart" ? (
                        <div className="hedge-accounting-btn text-center mb-10">
                          <Button
                            disabled={
                              moment().isBefore(
                                moment("13-12-2023", "DD-MM-YYYY")
                              ) ||
                              moment().isSameOrAfter(
                                moment("13-01-2024", "DD-MM-YYYY")
                              )
                            }
                            onClick={() =>
                              AddCart({
                                key: data.key,
                                id: data.id,
                                title: data.title,
                                category: data.category,
                                price: moment().isSameOrBefore(
                                  moment("31-12-2023", "DD-MM-YYYY")
                                )
                                  ? 3000
                                  : data.price,
                                currency: data.currency,
                                img: data.img,
                              })
                            }
                          >
                            Add To Cart
                          </Button>
                        </div>
                      ) : obj === "downloadBrochure" ? (
                        <div>
                          <div className="hedge-accounting-btn text-center mb-10">
                            <Button onClick={() => downloadBrochure(data.key)}>
                              Download Brochure
                            </Button>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <Col span={24} className="text-center mt-40 mb-40">
              <Spin tip="Loading..."></Spin>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
// export default data;
export default connect(null, { AddCart })(FinanceAndTreasuryBootcamp);
