import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Button, Table } from "antd";
import {
    NavLink,
} from "react-router-dom";
import "./skill-up-challenge.css";
import AppBreadCrumb from '../header/breadcrumb';
import { AddCart } from '../../actions/action'
import { connect } from 'react-redux';



function SkillUpChallenge({ skillUpChallenge, AddCart }) {
    const [addItem, setAddItem] = useState({});

    const columns = [
        {
            title: 'Scholarship Percentage',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'No. of Scholarships',
            dataIndex: 'no',
            key: 'no',
        }
    ];


    useEffect(() => {
        const temp = {
            key: skillUpChallenge.key,
            id: skillUpChallenge.id,
            title: skillUpChallenge.title,
            category: skillUpChallenge.category,
            price: skillUpChallenge.price,
            currency: skillUpChallenge.currency,
            img: skillUpChallenge.img
        }
        setAddItem(temp);
    }, []);


    return (
        <Row className="pt-82 bg-grey2">
            <Col className="mb-40" span={24}>
                <AppBreadCrumb></AppBreadCrumb>
            </Col>
            <Col span={22} offset={1}>
                {skillUpChallenge && Object.keys(skillUpChallenge).length ? (
                    <Row className="skill-up-challenge-sec mt-40 mb-40">
                        <Col span={17} className="description-sec pr-15">
                            <Row className="description-sec-wrapper">
                                <Col span={24} className="sec-title mb-20">
                                    <h3 >CAFTA Skill-Up Challenge</h3>
                                </Col>
                                <Col span={24} className="about-challenge mt-20">
                                    <p className="tagline">{skillUpChallenge.tagline}</p>
                                    <p>{skillUpChallenge.description.p1} {skillUpChallenge.description.p2}</p>
                                    <p className="fw-600">{skillUpChallenge.description.list1.heading}</p>
                                    <ol type="1">
                                        <li>{skillUpChallenge.description.list1.l1}</li>
                                        <li>{skillUpChallenge.description.list1.l2}</li>
                                    </ol>
                                    <p>{skillUpChallenge.description.p3}</p>
                                    <p className="fw-600">{skillUpChallenge.description.list2.heading}</p>
                                    <ol type="1">
                                        <li>{skillUpChallenge.description.list2.l1}</li>
                                        <li>{skillUpChallenge.description.list2.l2}</li>
                                        <li>{skillUpChallenge.description.list2.l3}</li>
                                    </ol>
                                    <p>
                                        {skillUpChallenge.description.link.text} <NavLink to="/products/scholar/batches">{skillUpChallenge.description.link.linkText} </NavLink>.
                                    {skillUpChallenge.description.p4} <b style={{ fontWeight: 'bold' }}>{skillUpChallenge.description.bold}</b>
                                    </p>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={7}>
                            <Col className="pl-15 other-details-sec">
                                <Row className="other-details-sec-wrapper">
                                    <Col span={24} className="mb-20">
                                        <h4>Fee and Other Details</h4>
                                    </Col>
                                    <Col span={24}>
                                        <div className="mb-10">
                                            <div className="fw-600">{skillUpChallenge.courseMode.text}</div>
                                            <div>{skillUpChallenge.courseMode.value}</div>
                                        </div>
                                        <div className="mb-10">
                                            <div className="fw-600">{skillUpChallenge.registrationFee.text}</div>
                                            <div>{skillUpChallenge.registrationFee.currency} {skillUpChallenge.registrationFee.price} (inclusive of 18% GST)</div>
                                        </div>
                                        <div className="mb-10 skill-up-challenge-btn text-center">
                                            <Button onClick={() => AddCart(addItem)} className="">Add  To Cart</Button>
                                        </div>
                                        <div className="mb-10">
                                            <div className="fw-600">{skillUpChallenge.eligibility.text}</div>
                                            <div>{skillUpChallenge.eligibility.value}</div>
                                        </div>
                                        <Table className="scholarship-table" dataSource={skillUpChallenge.scholorshipType.grid} columns={columns} pagination={{ position: ['none', 'none'] }} />
                                        <b style={{fontWeight: 'bold'}}>Scholarship worth 5.5L up for grab.</b>
                                    </Col>
                                </Row>
                            </Col>

                            
                            
                        </Col>

                        <Col span={24} className="global-finance-sec mt-20">
                            <Row className="global-finance-sec-wrapper">
                                <Col span={24} className="sec-title mb-20">
                                    <h3 >About the Course </h3>
                                </Col>
                                <Col span={24} className="mt-20">
                                    <p className="fw-600">The program “Decoding Global Financial Markets” contains the below topics</p>
                                    <ul>
                                        <li>Overview of global financial markets</li>
                                        <li>Participants in the financial markets and financial institutions</li>
                                        <li>Understand different global financial markets</li>
                                        <ul>
                                            <li>Stock exchanges</li>
                                            <li>Investment market</li>
                                            <li>FX & Commodity market</li>
                                        </ul>
                                        <li>Basics of factors affecting financial markets</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} className="global-finance-sec mt-20">
                            <Row className="global-finance-sec-wrapper">
                                <Col span={24} className="sec-title mb-20">
                                    <h3 >Why Global financial markets? </h3>
                                </Col>
                                <Col span={24} className="mt-20">
                                    <p className="fw-600">{skillUpChallenge.globalFinance.list1.heading}</p>
                                    <ul>
                                        <li>{skillUpChallenge.globalFinance.list1.l1}</li>
                                        <li>{skillUpChallenge.globalFinance.list1.l2}</li>
                                        <li>{skillUpChallenge.globalFinance.list1.l3}</li>
                                        <li>{skillUpChallenge.globalFinance.list1.l4}</li>
                                    </ul>
                                    <p>{skillUpChallenge.globalFinance.p1}</p>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} className="why-participate-sec mt-20">
                            <Row className="why-participate-sec-wrapper">
                                <Col span={24} className="sec-title mb-20">
                                    <h3 >{skillUpChallenge.whyParticipate.list1.heading}</h3>
                                </Col>
                                <Col span={24} className="mt-20">
                                    {/* <p className="fw-600">{skillUpChallenge.whyParticipate.list1.heading}</p> */}
                                    <ul>
                                        <li>{skillUpChallenge.whyParticipate.list1.l1}</li>
                                        <li>{skillUpChallenge.whyParticipate.list1.l2}</li>
                                        <li>{skillUpChallenge.whyParticipate.list1.l3}</li>
                                        <li>{skillUpChallenge.whyParticipate.list1.l4}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className="why-participate-sec mt-20">
                            <Row className="why-participate-sec-wrapper">
                                    <Col span={24} className="sec-title mb-20">
                                        <h3 >{skillUpChallenge.whyParticipateImp.list1.heading}</h3>
                                    </Col>
                                <Col span={24} className="mt-20">
                                    {/* <p className="fw-600">{skillUpChallenge.whyParticipateImp.list1.heading}</p> */}
                                    <ul>
                                        <li>{skillUpChallenge.whyParticipateImp.list1.l1}</li>
                                        <li>{skillUpChallenge.whyParticipateImp.list1.l2}</li>
                                        <li>{skillUpChallenge.whyParticipateImp.list1.l3}</li>
                                        <li>{skillUpChallenge.whyParticipateImp.list1.l4}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className="why-participate-sec mt-20">
                            <Row className="why-participate-sec-wrapper">
                                    <Col span={24} className="sec-title mb-20">
                                        <h3 >{skillUpChallenge.howToParticipate.list1.heading}</h3>
                                    </Col>
                                    {/* <p className="fw-600">{skillUpChallenge.howToParticipate.list1.heading}</p> */}
                                <Col span={24} className="mt-20">
                                    <ul>
                                        <li>{skillUpChallenge.howToParticipate.list1.l1}</li>
                                        <li>{skillUpChallenge.howToParticipate.list1.l2}</li>
                                        <li>{skillUpChallenge.howToParticipate.list1.l3}</li>
                                        <li>{skillUpChallenge.howToParticipate.list1.l4}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                ) : (
                    <Row>
                        <Col span={24} className="text-center mt-40 mb-40">
                            <Spin tip="Loading..."></Spin>
                        </Col>
                    </Row>

                )}
            </Col>
        </Row>

    );
}


export default connect(null, { AddCart })(SkillUpChallenge)
