import React, { useState, useEffect } from "react";

function NavSvg() {
    const [clipWidth, setClipWidth] = useState(71);
    const [svgDim, setSvgDim] = useState({width: '113px', height: '55px'});

    const handleScroll = () => {
       if (window.scrollY === 0) {
            setClipWidth(71);
            setSvgDim({width: '113px', height: '55px'});
        }
        else {
            setClipWidth(0);
            setSvgDim({width: '92.45px', height: '45px'});
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    return (
        <svg width={svgDim.width} height={svgDim.height} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 113 55" style={{transition: 'height 0.3s ease-out,width 0.3s ease-out'}}>
            <clipPath id="ey-logo-first-line">
            <rect x="42" y="36" width={clipWidth} height="10"></rect>
            </clipPath>
            <clipPath id="ey-logo-second-line">
            <rect x="42" y="46" width={clipWidth} height="10"></rect>
            </clipPath>
            <polygon className="ey-logo-yellow" fill="#ffe600" points="51.2,0 0,19.3 0,19.3 51.2,10 "></polygon>
            <polygon fill="#ffff" points="34.3,27 30,35.5 25.6,27 17,27 26.1,42.7 26.1,52.9 33.8,52.9 33.8,42.7 42.8,27 "></polygon>
            <polygon fill="#ffff" points="7.7,42.7 17,42.7 17,37.3 7.7,37.3 7.7,33 18,33 14.6,27 0,27 0,52.9 20.6,52.9 20.6,47 7.7,47 "></polygon>
            <g clipPath="url(#ey-logo-second-line)" fill="#ffff">
            <path d="M99.8,51.9c-0.4,0-0.9-0.3-0.9-1.6c0-1.2,0.5-1.4,0.9-1.4c0.5,0,0.7,0.3,0.9,0.5v2C100.5,51.7,100.2,51.9,99.8,51.9z M100.7,48.3c-0.3-0.3-0.7-0.5-1.1-0.5c-1.2,0-2,1-2,2.6c0,1.7,0.7,2.7,1.9,2.7c0.4,0,0.8-0.2,1.1-0.5V53h1.2v-7.1l-1.2,0.6L100.7,48.3L100.7,48.3z"></path>
            <polygon points="95.5,46.4 96.7,45.8 96.7,48.4 96.7,53 95.5,53 "></polygon>
            <path d="M94.6,49.3C94.3,49.2,94,49,93.7,49c-0.6,0-0.9,0.4-0.9,1.2V53h-1.2v-5h1.2v0.4c0.3-0.4,0.7-0.5,1.1-0.5c0.3,0,0.7,0.1,0.9,0.3L94.6,49.3z"></path>
            <path d="M88.6,51.9c-0.6,0-1-0.6-1-1.5s0.4-1.5,1-1.5s1,0.6,1,1.5C89.6,51.3,89.2,51.9,88.6,51.9z M88.6,47.8c-1.3,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6s2.3-1.1,2.3-2.6C90.8,48.9,89.9,47.8,88.6,47.8z"></path>
            <path d="M84.8,47.9H86l-1.4,5h-1.1l-0.5-2c-0.1-0.4-0.2-0.8-0.3-1.1c0,0.2-0.2,0.8-0.3,1.1L81.9,53h-1.1l0,0l-1.4-5h1.3l0.5,1.9c0.1,0.3,0.2,0.9,0.3,1.2c0.1-0.4,0.2-0.9,0.3-1.3l0.5-1.9h1.1l0.5,1.9c0.1,0.3,0.2,0.9,0.3,1.3c0.1-0.3,0.2-0.8,0.3-1.3L84.8,47.9z"></path>
            <path d="M75.2,49.4v2c-0.2,0.3-0.4,0.5-0.9,0.5c-0.4,0-0.9-0.3-0.9-1.6c0-1.2,0.5-1.4,0.9-1.4C74.8,48.9,75.1,49.2,75.2,49.4z M75.2,48.3c-0.3-0.3-0.7-0.5-1.1-0.5c-1.2,0-2,1-2,2.6c0,1.7,0.7,2.7,1.9,2.7c0.4,0,0.8-0.2,1.1-0.5v0.1c0,0.6,0,1.3-1.6,1.3h-0.1l0.4,1l0,0c1.6,0,2.4-0.8,2.4-2.5v-4.6H75v0.4H75.2z"></path>
            <path d="M68.2,53H67v-5h1.2v0.4c0.3-0.3,0.7-0.5,1.3-0.5c1.2,0,1.8,0.8,1.8,2.3V53h-1.2v-2.8c0-0.8-0.3-1.2-0.9-1.2s-0.9,0.4-0.9,1.2V53H68.2z"></path>
            <polygon points="65.9,46.6 65.9,47.2 64.7,47.2 64.7,46 65.9,46 "></polygon>
            <polygon points="64.6,47.9 65.8,47.9 65.8,50.1 65.8,53 64.6,53 "></polygon>
            <path d="M62.3,49.6l1.5,3.4h-1.4l-1.1-2.3l-0.7,0.8V53h-1.2v-6.5l1.2-0.6v4c0.2-0.2,0.3-0.5,0.5-0.6l1-1.3h1.4L62.3,49.6z"></path>
            <path d="M58.7,49.3c-0.2-0.2-0.5-0.3-0.8-0.3c-0.6,0-0.9,0.4-0.9,1.2V53h-1.2v-5H57v0.4c0.3-0.4,0.7-0.5,1.1-0.5c0.3,0,0.7,0.1,0.9,0.3L58.7,49.3z"></path>
            <path d="M52.7,51.9c-0.6,0-1-0.6-1-1.5s0.4-1.5,1-1.5s1,0.6,1,1.5C53.7,51.3,53.3,51.9,52.7,51.9z M52.7,47.8c-1.3,0-2.3,1.1-2.3,2.6s1,2.6,2.3,2.6s2.3-1.1,2.3-2.6C55,48.9,54,47.8,52.7,47.8z"></path>
            <path d="M48.9,47.9h1.2l-1.4,5h-1.1L47,50.8c-0.1-0.4-0.2-0.8-0.3-1.1c0,0.2-0.2,0.8-0.3,1.1L46,52.9h-1l-1.4-5h1.3l0.5,1.9c0.1,0.3,0.2,0.9,0.3,1.2c0.1-0.4,0.2-0.9,0.3-1.3l0.5-1.9h1.1l0.5,1.9c0.1,0.3,0.2,0.9,0.3,1.3c0.1-0.3,0.2-0.8,0.3-1.3L48.9,47.9z"></path>
            </g>
            <g clipPath="url(#ey-logo-first-line)" fill="#ffff">
            <path d="M112.7,40.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.6,0-0.9,0.4-0.9,1.2v2.7h-1.2v-5h1.2v0.4c0.3-0.4,0.7-0.5,1.1-0.5c0.3,0,0.7,0.1,0.9,0.3L112.7,40.5z"></path>
            <path d="M105.9,41.1c0-0.6,0.4-1,0.9-1c0.7,0,0.9,0.5,0.9,1H105.9z M107.9,42.7c-0.2,0.2-0.5,0.4-1,0.4c-0.2,0-1-0.1-1.1-1.1h3.1c0-0.1,0-0.3,0-0.4c0-1.6-0.8-2.6-2.1-2.6c-1.3,0-2.2,1.1-2.2,2.6c0,1.6,0.9,2.6,2.4,2.6c0.7,0,1.3-0.3,1.8-0.8L107.9,42.7z"></path>
            <path d="M102.9,42.4c0,0.4,0.2,0.7,0.5,0.7s0.5-0.1,0.7-0.2L104,44c-0.3,0.2-0.6,0.2-1,0.2c-1,0-1.3-0.8-1.3-1.5v-2.4h-0.8v-1.2h0.8v-1.3l1.2-0.6v1.9h1.1v1.2h-1.1C102.9,40.3,102.9,42.4,102.9,42.4z"></path>
            <path d="M100.5,44c-0.3,0.2-0.6,0.2-1,0.2c-1,0-1.3-0.8-1.3-1.5v-2.4h-0.8v-1.2h0.8v-1.3l1.2-0.6v1.9h1.1v1.2h-1.1v2.1c0,0.4,0.2,0.7,0.5,0.7s0.5-0.1,0.7-0.2L100.5,44z"></path>
            <path d="M93.9,41.1c0-0.6,0.4-1,0.9-1c0.7,0,0.9,0.5,0.9,1H93.9z M96,42.7c-0.2,0.2-0.5,0.4-1,0.4c-0.2,0-1.1-0.1-1.1-1.1H97c0-0.1,0-0.3,0-0.4c0-1.6-0.8-2.6-2.1-2.6c-1.3,0-2.2,1.1-2.2,2.6c0,1.6,0.9,2.6,2.4,2.6c0.7,0,1.3-0.3,1.8-0.8L96,42.7z"></path>
            <path d="M89.9,40.1c0.5,0,0.9,0.4,0.9,1.4s-0.3,1.6-0.9,1.6c-0.5,0-0.8-0.3-0.9-0.5v-2C89.2,40.3,89.5,40.1,89.9,40.1z M90.1,39c-0.4,0-0.8,0.2-1.1,0.5v-2.3l-1.2,0.6v6.4H89v-0.4c0.3,0.3,0.7,0.5,1.1,0.5c1.2,0,1.9-1,1.9-2.7C92.1,40,91.3,39,90.1,39z"></path>
            <path d="M81.5,42.5c0-0.4,0.3-0.7,0.9-0.7c0.4,0,0.7,0.1,1,0.2v0.7c-0.2,0.3-0.5,0.5-1,0.5C81.8,43.2,81.5,42.9,81.5,42.5z M81.1,40.3c0.4-0.2,0.8-0.3,1.3-0.3c0.6,0,0.9,0.2,0.9,0.7V41c-0.3-0.1-0.7-0.2-1.1-0.2c-0.9,0-2,0.4-2,1.7s1,1.8,1.9,1.8c0.4,0,0.9-0.1,1.2-0.5v0.4h1.2v-3.4c0-1.1-0.8-1.7-2-1.7c-0.7,0-1.3,0.2-1.9,0.5L81.1,40.3z"></path>
            <path d="M76,40.6v2c-0.2,0.3-0.4,0.5-0.9,0.5c-0.4,0-0.9-0.3-0.9-1.6c0-1.2,0.5-1.4,0.9-1.4C75.6,40.1,75.9,40.4,76,40.6z M76,39.5c-0.3-0.3-0.7-0.5-1.1-0.5c-1.2,0-2,1-2,2.6c0,1.7,0.7,2.7,1.9,2.7c0.4,0,0.8-0.2,1.1-0.5v0.1c0,0.6,0,1.3-1.6,1.3h-0.1l0.4,1l0,0c1.6,0,2.4-0.9,2.4-2.5v-4.6h-1C76,39.1,76,39.5,76,39.5z"></path>
            <path d="M69.1,44.1h-1.2v-5h1.2v0.4c0.3-0.3,0.7-0.5,1.3-0.5c1.2,0,1.8,0.8,1.8,2.3v2.8H71v-2.8c0-0.8-0.3-1.2-0.9-1.2s-0.9,0.4-0.9,1.2L69.1,44.1L69.1,44.1z"></path>
            <polygon points="66.7,37.8 66.7,38.4 65.5,38.4 65.5,37.2 66.7,37.2 "></polygon>
            <polygon points="65.5,39.1 66.7,39.1 66.7,40.7 66.7,44.1 65.5,44.1 "></polygon>
            <path d="M62.3,43.1c-0.4,0-0.9-0.3-0.9-1.6c0-1.2,0.5-1.4,0.9-1.4c0.5,0,0.7,0.3,0.9,0.5v2C62.9,42.9,62.7,43.1,62.3,43.1z M63.1,39.5C62.8,39.2,62.4,39,62,39c-1.2,0-2,1-2,2.6c0,1.7,0.7,2.7,1.9,2.7c0.4,0,0.8-0.2,1.1-0.5v0.4h1.2V37L63,37.6L63.1,39.5L63.1,39.5z"></path>
            <polygon points="58,37.6 59.2,37 59.2,40.8 59.2,44.2 58,44.2 "></polygon>
            <polygon points="56.9,37.8 56.9,38.4 55.7,38.4 55.7,37.2 56.9,37.2 "></polygon>
            <polygon points="56.9,40.9 56.9,44.2 55.7,44.2 55.7,39.2 56.9,39.2 "></polygon>
            <path d="M50.2,42v-2.8h1.2V42c0,0.8,0.3,1.2,0.9,1.2c0.6,0,0.9-0.4,0.9-1.2v-2.8h1.2v5h-1.2v-0.4c-0.3,0.3-0.7,0.5-1.2,0.5C50.4,44.3,50.2,42.8,50.2,42z"></path>
            <path d="M46.9,39.9h-1.6v-1.5h1.5c0.6,0,0.9,0.2,0.9,0.7C47.8,39.5,47.6,39.9,46.9,39.9z M46.9,43h-1.6v-2h1.6c0.7,0,1,0.3,1,1C48,42.6,47.6,43,46.9,43z M49.3,41.9c0-1-0.7-1.4-1-1.5c0.5-0.3,0.8-0.8,0.8-1.4c0-1.1-0.8-1.8-2.1-1.8h-3v6.9h2.9C48.4,44.1,49.3,43.3,49.3,41.9z"></path>
            </g>
        </svg>
    );
}

export default NavSvg;