import React, { useState, useEffect } from "react";
import './header.css';
import NavSvg from '../nav-svg/nav-svg'
import {
  NavLink,
  useLocation
} from "react-router-dom";
import { Badge } from 'antd';
import { connect } from 'react-redux'
import { useAppContext } from "../../lib/contextLib";
import { Auth } from "aws-amplify";
import { Store } from 'react-notifications-component';


function Header({numberCart, group}) {

  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [activeDropdown, setActiveDropdown] = useState(true);
  const location = useLocation();

  // console.log(group);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/products')) {
      setActiveDropdown(true);
    } else {
      setActiveDropdown(false);
    }
    // switch(path) {
    //   case '/products/scholar':
    //     setActiveDropdown(true);
    //     break;
    //   case '/products/skill-up-challenge':
    //     setActiveDropdown(true); 
    //     break;
    //   case '/products/executives':
    //     setActiveDropdown(true); 
    //     break;
    //   case '/products/executives':
    //     setActiveDropdown(true); 
    //       break;
    //   default:
    //     setActiveDropdown(false);
    // }
  }, [location]);

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);

  }

  const showNotification = () => {
      Store.addNotification({
          title: "Not Available!!",
          message: "COMING SOON",
          type: "info",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__flipInx"],
          animationOut: ["animate__animated", "animate__flipOutx"],
          dismiss: {
              duration: 5000,
              onScreen: true,
              showIcon: true
          }
      });
  }


  return (
    <nav id="navbar" className="app-nav nav navbar navbar-default navbar-fixed-top">
      <div className="nav-container nav-content">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          <NavLink to="/home" className="navbar-brand ">
            <NavSvg className="brand-logo" />
          </NavLink>
          <NavLink to="/contact-us" className="contactus-button yellow">
            For free college webinar contact us
          </NavLink>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav  navbar-nav  navbar-right">
            <li className="list-item">
              <NavLink activeClassName="active" to="/home">Home</NavLink>
            </li>
            {(group === 'admin') && <li className="list-item">
              <NavLink activeClassName="active" to="/admin">Admin</NavLink>
            </li>}
            <li className="dropdown">
              <a href="#" className={activeDropdown ? 'dropdown-toggle active': 'dropdown-toggle'}  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                Products <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <NavLink to="/products/scholar"> Scholar</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/products/skill-up-challenge"> Skill-Up Challenge</NavLink>
                </li> */}
                <li>
                  <NavLink to="/products/executives"> Executives</NavLink>
                </li>
                <li>
                  <NavLink to="/products/hedge-accounting"> Hedge Accounting</NavLink>
                </li>
                <li>
                  <NavLink to="/products/cafta-case-championship"> Cafta Case Championship</NavLink>
                </li>
                <li>
                  <NavLink to="/products/finance-and-treasury-bootcamp">
                    Finance And Treasury Bootcamp
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/products/cafta-sprint-series">
                    CAFTA Sprint Series
                  </NavLink>
                </li>
              </ul>
            </li>
            {
              isAuthenticated ? (
                <li className="list-item signin-item"> <NavLink onClick={handleLogout} to="sign-in">Sign Out</NavLink> </li>
              ) : (
                <li className="list-item signin-item">
                  <NavLink to="/sign-in">Sign In</NavLink>
                </li>
              )
            }
            
            <li className="list-item cart-item">
              <NavLink to="/my-cart">
                <Badge count={numberCart}>
                  <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>
                </Badge>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  );
}

const mapStateToProps = state => {
  return {
    numberCart: state.caftaModules.numberCart
  }
}

export default connect(mapStateToProps, null)(Header)

