import getModuleList from '../api/api'

export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT';
export const GET_NUMBER_CART = 'GET_NUMBER_CART';
export const ADD_CART = 'ADD_CART' ;
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_CART = 'DELETE_CART';
export const GET_MODULE_ITEM = 'GET_MODULE_ITEM';
export const CLEAR_CART = 'CLEAR_CART';

export const actFetchProductsRequest = (cancelSource) => {
    return (dispatch) => {
        return getModuleList('data/data.json', 'GET', null, cancelSource).then(res => {
            // console.log(res.data.moduleList);
            dispatch(GetAllProduct(res.data.moduleList));
        });
    }
}

/*GET_ALL_PRODUCT*/
export function GetAllProduct(payload){
    return{
        type:'GET_ALL_PRODUCT',
        payload
    }
}

/*GET_MODULE_ITEM*/
export function GetModuleItem(payload){
    return{
        type:'GET_MODULE_ITEM',
        payload
    }
}

/*GET NUMBER CART*/
export function GetNumberCart(){
    return{
        type:'GET_NUMBER_CART'
    }
}

export function AddCart(payload){
    return {
        type:'ADD_CART',
        payload
    }
}

export function UpdateCart(payload){
    return {
        type:'UPDATE_CART',
        payload
    }
}

export function DeleteCart(payload){
    return{
        type:'DELETE_CART',
        payload
    }
}

export function IncreaseQuantity(payload){
    return{
        type:'INCREASE_QUANTITY',
        payload
    }
}

export function DecreaseQuantity(payload){
    return{
        type:'DECREASE_QUANTITY',
        payload
    }
}

export function ClearCart(payload){
    return{
        type:'CLEAR_CART',
        payload
    }
}
